import React from 'react';
import "./index.css"
import Button from '../../common/Button';
// import backgroundwaves from '../../../../src/staticassets/solutions/second/background/backgroundwave2@2x.png';
// import marketplaceX from '../../../../src/staticassets/solutions/second/images/marketplaceX@2x.png';

const SecoundSection = () => {
  return (
      <>

<section className="thank-secound">
<div className="container">

<div className="txt-th">Thanks for contacting us.<br /> We will get back to you as soon we can.</div>
<div className="btn">
    <Button path="" btnName="GO TO HOMEPAGE" />

    </div>
</div>
</section>
</>
  );
}

export default SecoundSection;