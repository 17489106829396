import React from 'react';
// import { ThemeContext } from '../../utils/theme';
import Footer from '../../components/common/Footer';

import Header from '../../components/common/Header';
// import waves1 from '../../staticassets/about/first/background/backgroundwaves1@2x.png';
import "./index.css"
import FirstSection from '../../components/about/FirstSection';
import SecoundSection from '../../components/about/SecoundSection';
import ThirdSection from '../../components/about/ThirdSection';

const Publishers = () => {
  return (
    <>
<Header />
{/* <div className="topimg abt"><img src={waves1} alt="waves1" /></div> */}
    <div className="about">
    <FirstSection />

    <SecoundSection />
    <ThirdSection />
    </div>
<Footer />

    </>
  );
}

export default Publishers;