import React from 'react';
// import "./index.css"
// import backgroundwaves from '../../../../src/staticassets/solutions/second/background/backgroundwave2@2x.png';
import Form from '../../Form';

const SecoundSection = () => {
  return (
      <>
<section className="cont-secound">
<Form />
</section>
</>
  );
}

export default SecoundSection;