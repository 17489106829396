import React from 'react';
// import { ThemeContext } from '../../utils/theme';
import { useLocation } from "react-router-dom"

import Footer from '../../components/common/Footer';

import Header from '../../components/common/Header';
// import waves1 from '../../staticassets/contact/background/backgroundwaves1@2x.png';
// src/staticassets/contact/background/backgroundwaves1@2x.png
import "./index.css"
import FirstSection from '../../components/contact/FirstSection';
import SecoundSection from '../../components/contact/SecoundSection';
import ContactAdvisor from '../ContactAdvisor';

const Contact = () => {
  const location = useLocation();
  console.log('loca.pathname',location.pathname);

  return (
    <>
    { location.pathname === '/contact' &&
    (
      <>
<Header />
<div className="contact">
{/* <div className="topimg"><img src={waves1} alt="waves1" />
</div> */}

    <FirstSection />
      <div className="container">
    <SecoundSection />
    </div>
    </div>

<Footer />
</>
    )
}


{ location.pathname === '/advertiser-contact' &&
    (
      <>
<ContactAdvisor />
</>
    )
}

    </>
  );

}

export default Contact;