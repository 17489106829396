import React from 'react';
// import Button from '../../common/Button';
import "./index.css"
import Reviwes from './Reviwes';
import Carousel from "react-multi-carousel";
import hiwi from "../../../staticassets/homepage/fifth/HIWslides/slide1@2x.png";
import hiwi2 from "../../../staticassets/homepage/fifth/HIWslides/SLIDE2@2x.png";
import hiwi3 from "../../../staticassets/homepage/fifth/HIWslides/SLIDE3@2x.png";
import hiwi4 from "../../../staticassets/homepage/fifth/HIWslides/SLIDE4@2x.png";
import hiwi5 from "../../../staticassets/homepage/fifth/HIWslides/SLIDE5@2x.png";
// import hiwi from "../../../staticassets/homepage/fifth/slideshow/slide 1/Hiw1@2x.png";
// import hiwi2 from "../../../staticassets/homepage/fifth/slideshow/slide 2/Hiw2@2x.png";
// import hiwi3 from "../../../staticassets/homepage/fifth/slideshow/slide 3/Hiw3@2x.png";
// import hiwi4 from "../../../staticassets/homepage/fifth/slideshow/slide 4/Hiw4@2x.png";
// import hiwi5 from "../../../staticassets/homepage/fifth/slideshow/slide 5/Hiw5@2x.png";
import 'react-multi-carousel/lib/styles.css';

const Home = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  const review = [
    {
      image: hiwi,
      disxtshead: 'Seamless Platform Integration',
      text: 'Comprehensive product selection and offer matching increases conversion and performance',
    },
    {
      image: hiwi2,
      disxtshead: 'User-Intent Marketing',
      text: 'Native commerce placed where intent is highest',
    },
    {
      image: hiwi3,
      disxtshead: 'Immersive UX',
      text: 'Our custom UX helps users transition between inspiration and purchase',
    },
    {
      image: hiwi4,
      disxtshead: 'Maintain Integrity',
      text: 'We preserve your editorial voice and authority',
    },
    {
      image: hiwi5,
      disxtshead: 'Impeccable Matching',
      text: 'AI-based matching of context, intent, content, products, and offers',
    }
  ];
  return (
    <>
      <div className=" svgflip1">


        <svg style={{ position: 'relative', top: 1 }} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1920 186">
          <path id="Trazado_2013" data-name="Trazado 2013" d="M-1.489,24.244s86.441-7.222,219.764,1.533S811.592,150.87,1030.661,129.387s537.776-76.824,706.595-83.079,181.253-8.228,181.253-8.228V208h-1920Z" transform="translate(1.491 -21.445)" fill="#d9ebf6" />
          <path id="Trazado_2012" data-name="Trazado 2012" d="M-1.491.035s102.9-1.411,349.288,25.12S726.811,76.594,992.1,76.594s368.48-31.787,625.261-45.892,301.144-15.315,301.144-15.315V166h-1920Z" transform="translate(1.491 22)" fill="#305ab9" />
        </svg>
      </div>
      <div className="carousal1bdisc">
        {/* <div className="bckimgcarosal1"><img src={backgroundwaves3} alt="yellowwaves" height="500px" /></div> */}
        {/* <div className="container">
<div className="row"> */}
        <div className="container">

          <div className="cahead"><h3 style={{ paddingBottom: '70px' }}>Discover How It <b>Works</b></h3> </div>
        </div>
        <Carousel
          ssr
          partialVisbile
          showDots={true}
          swipeable={true}
          arrows={false}
          // deviceType={deviceType}
          itemClass="image-item1"
          containerClass="carousel-container"
          responsive={responsive}
        >
          {review.map(image => {
            return (
              <div className="carosoulb">
                <Reviwes m={image} />
              </div>
            );
          })}
        </Carousel>
        {/* </div>
</div> */}
      </div>
      <div style={{ maxWidth: '100%', overflow: 'hidden' }} className=" svgflip1">

        {/* <svg style={{ position: 'relative', top: -2 }} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1920 230">
          <path id="Trazado_2085" data-name="Trazado 2085" d="M-1.489,846h1920v218.029s-108.314,6.878-278.519,13.068c-45.615,1.659-102.05.721-143.021,0-112.32-1.977-125.22-2.041-262.81-13.068s-271.6-59.433-408.206-74.2S542.035,968.779,299.531,963-1.491,951.845-1.491,951.845Z" transform="translate(1.491 -846)" fill="#d9ebf6" />
          <path id="Trazado_2086" data-name="Trazado 2086" d="M-1.491,804h1920V995.481s-58.6-.769-301.144-1.026-362.2,26.132-667.545,0S620.146,929.688,396,889.926-1.491,866.945-1.491,866.945Z" transform="translate(1.491 -804)" fill="#305ab9" />

        </svg> */}
        <svg style={{ position: 'relative', top: -2 }} version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 230" width="1920" height="230">
          <path fill="#d9ebf6" id="Trazado_2085" d="m0 0h1920v218c0 0-108.3 6.9-278.5 13.1-45.6 1.7-102.1 0.7-143 0-112.4-2-125.3-2-262.8-13.1-137.6-11-271.6-59.4-408.3-74.2-136.6-14.7-283.9-21-526.4-26.8-242.5-5.8-301-11.2-301-11.2z" />
          <path fill="#305ab9" id="Trazado_2086" d="m0-3h1920v194.3c0 0-58.6-0.8-301.1-1-242.6-0.3-362.2 26.5-667.6 0-305.3-26.5-329.7-65.7-553.8-106.1-224.2-40.4-397.5-23.3-397.5-23.3z" />
        </svg>
      </div>
    </>
  );
}

export default Home;