import React from 'react';
import Button from '../../common/Button';
import "./index.css"
import firstsectionimg from '../../../../src/staticassets/homepage/first/images/homepage-first-image@2x.png';
import accuweatherlogo from '../../../../src/staticassets/homepage/first/assets/accuweatherlogo.png';
import dailymaillogo from '../../../../src/staticassets/homepage/first/assets/dailymaillogo.png';
import fandomlogo from '../../../../src/staticassets/homepage/first/assets/fandomlogo.png';
const Home = () => {
  return (
    <>
      <section className="topsectionhome">
        <div className="container">

          <article className="bannersectionhome">
            <div className="row">
              <div className="col-12 col-6   one">
                <h1>Leading Commerce Platform for Publishers at Scale</h1>
                <p>Diversify and create incremental revenue at scale immediately. Grow traffic and increase revenue with intent<sup>x</sup> ’s affiliate commerce solutions.
                </p>
                <div className="btn">
                  <Button path="contact" btnName="START MONETIZING" />
                  <ul>
                    <li><span><img src={accuweatherlogo} alt="accuweatherlogo" /></span> <span><img src={dailymaillogo} alt="dailymaillogo" /></span><span><img src={fandomlogo} alt="fandomlogo" /></span></li>
                  </ul>
                </div>
              </div >
              <div className="col-12 col-6 two"><div className="topimage"><img src={firstsectionimg} alt="firstsection" /></div></div>
            </div>
          </article>
        </div>
        {/* <div className="svgflip">
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1440 320" >
  <path class="w1"  d="m-1.2 155.9c0 0 45.8-1.8 68.7-0.8 96.9 4.3 213.1 15.7 289.6 28.9 120 19 241.7 41.5 361.7 62.8 120 21.7 269.8 29.2 389.8 2.9C1228.6 222.9 1320 108 1380 76l60-32v224l-60 52-293-1.1H734L363.5 317.2 75.8 322.4 0 268c0 0-1.2-81.2-1.2-112.1z"/>

  <path class="w2"   d="m-1.2 186.8c0 0 45-1.1 67.5-2 190.8-7.9 184.4-4 297.5 2 120 5.3 241.2 42.3 361.2 63.6 120 21.7 245 23 365-3.3 120-26.7 194-95.4 290.1-145.1 20.1-10.4 60-32 60-32V294H1380 1080 720 360 60 0Z" />

  <path class="w3" d="M0 224 60 213.3C120 203 228.9 182.5 348.9 188.2 468.9 193.5 600 224 720 245.3 840 267 960 277 1080 250.7 1200 224 1320 160 1380 128l60-32V320H1380 1080 720 360 60 0Z" />
</svg>
</div> */}
        <div className="svgflip">
          <svg style={{ position: 'relative', top: -1 }} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1920 284.37">
            <path id="Trazado_2084" data-name="Trazado 2084" d="M0,0H1920V213s-85.67-29.831-164.3-40.951-110.557-30.54-170.672-33-153.457,34.6-360.178,50.585-263.059-22.7-466.7,13.353S463.51,257.11,360,283.236s-188.3,5.312-231.925,12.674S0,315,0,315Z" fill="#f1f9fb" />
            <path id="Trazado_2083" data-name="Trazado 2083" d="M0,0H1920V136s-75.628-33.626-193.983-38.453-325.442,52.909-525.7,73.071-340.075-48.677-474.9-42.624S511.883,217.446,360,255.071,188.289,268.606,117.888,278.5,0,305,0,305Z" fill="#dae6f4" />
            <path transform="translate(0 -1)" id="Trazado_2082" data-name="Trazado 2082" d="M0,0H1920V105.581S1588.183,21.97,1296.97,43.45s-276.348,105.6-510.591,140.8-298.02-27.437-504.336,12.48S0,284.37,0,284.37Z" fill="#305ab9" />
          </svg>
        </div>
      </section>
    </>
  );
}

export default Home;
