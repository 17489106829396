import React from 'react';
import "./index.css"
// import backgroundwaves from '../../../../src/staticassets/solutions/second/background/backgroundwave2@2x.png';
import marketplaceX from '../../../../src/staticassets/solutions/second/images/marketplaceX@2x.png';
import widgetX from '../../../../src/staticassets/solutions/second/images/widgetX@2x.png';
import contentX from '../../../../src/staticassets/solutions/second/images/contentX@2x.png';

const SecoundSection = () => {
  return (
      <>
<section className="sol-secound">
{/* <div className="massive-ywaves1"><img src={backgroundwaves} alt="yellowwaves"style={{ width: '100%'}} /></div> */}
<div className="solbck">
<div className="container">
<div className="sol-third-head">
<h2>marketplace<sup>X</sup></h2>
<p>A native marketplace with incremental revenue and high-intent consumers.</p>
</div>
<div><img src={marketplaceX} alt="yellowwaves"style={{ width: '100%'}} /></div>

<div className="solsec1">
    <div className="col-7"><img src={widgetX} alt="yellowwaves"style={{ width: '100%'}} /></div>
    <div className="col-5"><h4>machine - learning widget<sup>X</sup></h4><p>Intelligent matching of contextually relevant products with engaging offers. High Click-Through-Rate and high CPM.</p></div>
</div>
<div className="solsec1">
    <div className="col-5">
        <h4>content<sup>X</sup></h4>
    <p>Inspiring, carefully curated shoppable content with a neutral editorial approach preserves publisher integrity.</p>
    <b>Third-Party Publishing</b><p>Years of expertise in top-performing on-site product coverage on sites like Amazon. Trend forecasting, keyword research, and editorial content help inspire shoppers and maximize revenue. We offer out-of-the-box data integration and a reliable CMS.</p>
    </div>
    <div className="col-7"><img src={contentX} alt="yellowwaves"style={{ width: '100%'}} /></div>
</div>
</div>
</div>
</section>
</>
  );
}

export default SecoundSection;