import React from 'react';
import Button from '../../common/Button';
import "./index.css"
import firstsectionimg from '../../../../src/staticassets/advertisers/first/image/retailerpartners@2x.png';

const Home = () => {
  return (
    <>
      <section className="topsection">

        <div className="container">
          <article className="bannersection">
            <div className="row">
              <div className="col-6  one">
                <h1>Reach High-Intent Consumers With Premium Content Publishers.</h1>
                <div className="btn">
                  <Button path="advertiser-contact" btnName="PARTNERSHIP" />

                </div>
              </div >
              <div className="col-6 two"><div className="topimage"><img src={firstsectionimg} alt="firstsection" /></div></div>
            </div>
          </article>
        </div>

      </section>
      <div className="svgflip2" style={{ marginTop: '-5px', width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
        <svg style={{ position: 'relative', top: -1 }} xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="0 0 1920 186" width="1920" height="186">
          <path fill="#d9ebf6" id="Trazado_2088" d="m0 0h1920v190c0 0-108.3 6.9-278.5 13.1-45.6 1.7-102.1 0.7-143 0-112.4-2-125.3-2-262.8-13.1-137.6-11-271.6-59.4-408.3-74.2-136.6-14.7-283.9-21-526.4-26.8-242.5-5.8-301-11.2-301-11.2z" />
          <path fill="#305ab9" id="Trazado_2089" d="m-2-1h1924v163.5c0 0-58.7-0.8-301.8-1-243-0.3-362.9 26.1-668.9 0-306-26.2-330.4-64.8-555-104.6-224.6-39.7-398.3-23-398.3-23z" />
        </svg>
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1920 185.978"><path id="Trazado_2088" data-name="Trazado 2088" d="M-1.489,874h1920v190.029s-108.314,6.878-278.519,13.068c-45.615,1.659-102.05.721-143.021,0-112.32-1.977-125.22-2.041-262.81-13.068s-271.6-59.433-408.206-74.2S542.035,968.779,299.531,963-1.491,951.845-1.491,951.845Z" transform="translate(1.491 -874)" fill="#d9ebf6"></path><path id="Trazado_2089" data-name="Trazado 2089" d="M-1.491,832h1920V995.481s-58.6-.769-301.144-1.026-362.2,26.132-667.545,0S620.146,929.688,396,889.926-1.491,866.945-1.491,866.945Z" transform="translate(1.491 -833)" fill="#305ab9"></path></svg> */}
      </div>
    </>
  );
}

export default Home;