import React from 'react';
import "./index.css"
// import adspendinggraphic from '../../../staticassets/publisher/third/graph/AffiliateSpendinginBilliongraph@2x.png';
// import adrevenues from '../../../staticassets/publisher/secound/icons/adrevenues.png';
// import backgroundwaves3 from '../../../../src/staticassets/about/fourth/background/backgroundwaves3@2x.png';
import location from '../../../../src/staticassets/about/fourth/assets/locationicon@2x.png';
import losangeles from '../../../../src/staticassets/about/fourth/images/losangeles@2x.png';
import bangalore from '../../../../src/staticassets/about/fourth/images/bangalore@2x.png';

const SecoundSection = () => {
  return (
    <>
      <section className="abt-third">
        <div className="svgflip2" style={{ width: '100%' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1920 280">
            <path id="Trazado_2094" data-name="Trazado 2094" d="M0,281H1919.691V237.888s-215.306,5.84-397.974-26.861-368.168,51.54-589.1-56.556-330.977-36.842-536.28-67S0,0,0,0Z" fill="#f4f7fd" />
            <path id="Trazado_2095" data-name="Trazado 2095" d="M0,281.5H1919.691V267.047s-215.306,6.055-397.974-27.85-368.168,53.438-589.1-58.638-330.977-36.821-536.28-68.094S0,0,0,0Z" fill="#d2e6fe" />

          </svg>
        </div>
        <div className="abt-third-back">

          {/* <div className="massive-ywaves1"><img src={backgroundwaves3} alt="yellowwaves" style={{ width: '100%'}} /></div> */}
         { /* <div className="container">
            <div className="row">
              <div className="col-12  third-left">
                <img src={location} alt="location" style={{ width: '111px', height: '111px' }} />
                <h2>Our Locations</h2>

              </div >
              <div className="col-12 third-right">
                <div className="left-location">
                  <div className="left-loc">
                    <img src={losangeles} alt="firstsection" style={{ width: 249, height: 149 }} />
                  </div>
                  <div className="right-loc">
                    <p>10880 Wilshire Blvd, Suite 1101,<br /> Los Angeles, CA 90024</p>
                  </div>
                </div>
                <div className="left-location">
                  <div className="left-loc">
                    <img src={bangalore} alt="firstsection" style={{ width: 249, height: 149 }} />
                  </div>
                  <div className="right-loc">
                    <p>#202, 1st AA Cross, 2nd Main Road,<br /> Kasturinagar, Bengaluru, <br /> Karnataka, India 560043</p>
                  </div>
                </div>
              </div>

            </div>

          </div> */ }
        </div>
      </section>

    </>
  );
}

export default SecoundSection;