import React from 'react';
import "./index.css"
// import adspendinggraphic from '../../../staticassets/publisher/third/graph/AffiliateSpendinginBilliongraph@2x.png';
// import adrevenues from '../../../staticassets/publisher/secound/icons/adrevenues.png';
import adrevenues from '../../../staticassets/publisher/fourth/graph/Keycomponents@2x.png';
import dots from '../../../staticassets/publisher/fourth/graph/dots.png';

const SecoundSection = () => {
  return (
    <>
      <section className="pub-fourth">
        <div className="container">
          <div className="row1">

            <div className="pub-fourth-head">
              <h2>Key Components</h2>
              <p>intent<sup>X</sup> helps publishers increase incremental revenue and traffic from ecommerce.</p>
            </div>
            <div className="circle">

              <div className="cfirst ">
                {/* <li className="fc clearfix"> */}
                {/* <p>PRODUCTS</p>
        <ul>
            <li className="sc">
              <p>USER <br /> EXPERIENCE</p>
                <ul>
                  <li className="tc">
                     <p>NEUTRALITY</p>
                     <ul>
                        <li className="foc">
                          <p>MATCHING</p>
                        </li>
                      </ul>

                  </li>
                </ul>
          </li>
        </ul> */}
                <div>
                  <img src={adrevenues} alt="circle" />
                </div>
              </div>

              <div className="cscou">
                <div className="csecound ">
                  <ul >
                    <li className="lc1">
                      <div className="smallcircle">
                        <img src={dots} alt="dots" />

                        {/* <ul>
          <li className=" ">
            <img src={dots} alt="dots" />
          </li>
          </ul> */}
                      </div>
                      <p>Additional eCommerce data for advertising team.</p>
                    </li>
                    <li className="lc2">
                      <div className="smallcircle">            <img src={dots} alt="dots" />
                      </div>
                      <p>Not replacing Publisher’s advertising revenue.</p>
                    </li>
                    <li className="lc3">
                      <div className="smallcircle">            <img src={dots} alt="dots" />
                      </div>
                      <p>Products available in all verticals to support scale.</p>
                    </li>
                    <li className="lc4">
                      <div className="smallcircle">            <img src={dots} alt="dots" />
                      </div>
                      <p>Products align with Publisher’s brand.</p>
                    </li>
                    <li className="lc5">
                      <div className="smallcircle">            <img src={dots} alt="dots" />
                      </div>
                      <p>Publisher has full control over the marketplace.</p>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>


        </div>
      </section>
    </>
  );
}

export default SecoundSection;