/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/require-default-props */
import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
// import Header from '../../View/Header';
// import Footer from '../../View/Footer';
import './index.css';
// import { ThemeContext } from '../../utils/theme';

export default function TermsOfService(props) {
  // const theme = useContext(ThemeContext);
  return (
    <div>
      <Helmet>
        <title>Terms of Service | intentX</title>
        <meta name="description" content="Review the terms of service for the intentX website." />
      </Helmet>
      <div style={{ marginTop: 100, minHeight: '64vh', padding: '10px' }}>
        <h2 style={{ textAlign: 'center' }}>
          INTENTX INC.
        </h2>
        <br />
        <h1 style={{ textAlign: 'center' }}>
          TERMS OF SERVICE
        </h1>
        <br />
        <p style={{ textAlign: 'center' }}> Last Updated: January 1, 2022</p>
        <br />
        <div style={{ maxWidth: 1200, margin: 'auto' }} className="terms-body">
          <p>
            These Terms of Service (“
            <span className="underlined">Agreement</span>
            ” or “
            <span className="underlined">Terms of Service</span>
            ”) are a legally binding agreement between you (the “
            <span className="underlined">User</span>
            ,” “
            <span className="underlined">you</span>
            ,” or “
            <span className="underlined">your</span>
            ”) and intentX Inc. (“
            <span className="underlined">intentX</span>
            ,” “
            <span className="underlined">we</span>
            ,” “
            <span className="underlined">us</span>
            ,” “
            <span className="underlined">our</span>
            ”). You acknowledge and agree that your use of the intentX platform (the “
            <span className="underlined">Platform</span>
            ”) through intentX’s website at
            {' '}
            <a href="http://www.intentx.com">www.intentx.com</a>
            {' '}
            (the “
            <span className="underlined">Website</span>
            ”) will be governed by this Agreement, our
            {' '}
            <a href="/privacy-policy">Privacy Policy</a>
            , and any related terms.
          </p>
          <br />
          <p>
            If you are unsure as to the terms of this Agreement, please do not proceed further and contact us at
            {' '}
            <a href="mailto:contact@intentx.com">contact@intentx.com</a>
            .
          </p>
          <br />
          <p>
            Your use of our Website or Platform shall constitute your acceptance of this Agreement and to all of the terms and conditions stated under this Agreement and our
            {' '}
            <a href="/privacy-polic">Privacy Policy</a>
            {' '}
            referenced herein.
          </p>
          <br />
          <p>
            PLEASE READ THE TERMS CONTAINED IN THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. PLEASE NOTE THAT THESE TERMS CONTAIN A BINDING AND MANDATORY ARBITRATION PROVISION AND CLASS ACTION/JURY TRIAL WAIVER PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS AND LIMITS REMEDIES AVAILABLE TO YOU IN THE EVENT OF CERTAIN DISPUTES.
          </p>
          <br />
          <p>
            BY ACCEPTING THIS AGREEMENT, EITHER BY CLICKING A BOX INDICATING YOUR ACCEPTANCE OR BY USING AND NAVIGATING THROUGH OUR PLATFORM THROUGH OUR WEBSITE, YOU AGREE THAT (A) YOU HAVE READ AND UNDERSTOOD THE AGREEMENT; (B) YOU REPRESENT THAT YOU ARE AT LEAST 18 YEARS OLD; (C) YOU CAN FORM A BINDING CONTRACT; AND (D) YOU ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS AS WELL AS OUR
            {' '}
            <a href="/privacy-policy">PRIVACY POLICY</a>
            {' '}
            REFERENCED HEREIN. IF YOU DO NOT AGREE WITH THIS AGREEMENT, YOU MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE OUR WEBSITE OR PLATFORM.
          </p>
          <br />
          <p>
            Capitalized terms not defined herein shall have the same meaning ascribed to them under our
            {' '}
            <a href="/privacy-policy">Privacy Policy</a>
            .
          </p>
          <br />
          <ol className="main-list">
            <li>
              <h3>INTRODUCTION TO INTENTX </h3>

              <p>
                <br />
                intentX is an eCommerce platform for publishers and consumers.  The intentX Platform is an advertising platform that helps publishers grow their eCommerce traffic and revenue.  The intentX Platform also provides consumers with content, deals, coupons, and cashback on their purchases.
              </p>
              <br />
            </li>
            <li>
              <h3>TERRITORIAL RESTRICTION</h3>

              <p>
                <br />
                Our Website and Platform are only available for use and access in the United States. Our Website, App, and Platform are not available for use or access by residents of, visitors to, or your employees who reside in the European Union (collectively a “
                <span className="underlined">European</span>
                ”). If you are a European, please do not access, register, and/or use our Website or Platform. If you are a resident of the United States (“
                <span className="underlined">US</span>
                ”), the laws of the State of Delaware, United States shall apply.  If you are a resident of any other country, please ensure compliance with all local laws prior to using our Website or Platform.  You must comply with these Terms of Service and our
                {' '}
                <a href="/privacy-policy">Privacy Policy</a>
                .
              </p>
              <br />
              <p>
                If you have any questions regarding this Section, please email us at
                {' '}
                <a href="mailto:contact@intentx.com">contact@intentx.com</a>
                .
              </p>
              <br />
            </li>
            <li>
              <h3>PRIVACY POLICY</h3>

              <p>
                <br />
                Our
                {' '}
                <a href="/privacy-policy">Privacy Policy</a>
                {' '}
                describes how we handle the personal and business information you provide to us when you register for our Website or Platform. You understand that through your use of our Website or Platform, you consent to the collection and use (as set forth in the
                {' '}
                <a href="/privacy-policy">Privacy Policy</a>
                ) of this information, including the transfer of this information to the US, and/or other countries for storage, processing and use by intentX and our affiliates.
              </p>
              <br />
            </li>
            <li>
              <h3>ELIGIBILITY &amp; ACCESS RESTRICTIONS</h3>

              <p>
                <br />
                To be eligible to use our Website or Platform, you must meet the following criteria and represent and warrant that you:

                (a) are 18 years of age or older; (b) are not currently restricted from accessing our Website or Platform, or not otherwise prohibited from having an account, (c) are not our competitor, or are not using our Website or Platform for reasons that are in competition with us; (d) will only maintain one registered account at any given time; (e) have full power and authority to enter into this Agreement and doing so will not violate any other agreement to which you are a party; (f) will not violate any of our rights, including intellectual property rights such as patent, copyright, and trademark rights; and (g) agree to provide at your cost all equipment, browser software, and internet access necessary to use our Website or Platform.
              </p>
              <br />
            </li>
            <li>
              <h3>REGISTRATION</h3>

              <p>
                <br />
                To use our Website and Platform, you can set up an account (“
                <span className="underlined">Account</span>
                ”) and create a password (“
                <span className="underlined">Password</span>
                ”) or you can use your social media account. You agree that all information you provide is true, accurate, current and complete, and you agree to maintain and promptly update such information to keep it current. You may not transfer or share your Password or Account (collectively, the “
                <span className="underlined">Account Information</span>
                ”) with anyone outside of your practice.

                You are responsible for maintaining the confidentiality of your Account Information and for all activities that occur under your Password or Account. You agree to immediately notify us upon becoming aware of any unauthorized use of your Account Information. intentX reserves the right to take any and all action, as it deems necessary, regarding the security of our Platform and your Account Information. Under no circumstances shall intentX be held liable to you for any claims or damages resulting from or arising out of your use of our Platform, your use of the Account Information, or your release of the Account Information to a third party.
              </p>
              <br />
            </li>
            <li>
              <h3>SERVICE LICENSE</h3>

              <p>
                <br />
                Subject to your compliance with the terms of this Agreement, we grant you a limited, non-exclusive, revocable, non-transferable, non-licensable, non-sublicensable license to (a) access and use our Website or Platform on any mobile device owned or otherwise controlled by you strictly in accordance with this Agreement, and (b) access, stream, and use on your mobile device any content made available in or otherwise accessible through our Website or Platform, strictly in accordance with this Agreement.
              </p>
              <br />
              <p>
                You will not use, copy, adapt, modify, prepare derivative works based upon our Website or Platform, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit our Website or Platform, except as expressly permitted in this Agreement. When using and accessing our Website or Platform, you need to make sure that your internet connection is adequate. You are solely responsible for your internet connection including and not limited to the applicable charges, rates, tariffs, and other fees that might apply.
              </p>
              <br />
              <p>
                YOU AGREE THAT WE ARE NOT LIABLE FOR ANY DAMAGES OR INJURY RESULTING FROM YOUR ACCESS OR USE OF OUR WEBSITE OR PLATFORM. WE PROVIDE NO WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE OR WARRANTY OF MERCHANTABILITY. THERE IS NO WARRANTY WHICH WILL EXTEND BEYOND THE DESCRIPTION ON THE FACE HEREOF. ANY DAMAGE ALLEGED FOR A LOSS OR INJURY IS LIMITED TO THE FEE, IF ANY, PAID TO INTENTX FOR THE ABILITY TO ACCESS OR USE OUR WEBSITE OR PLATFORM.
              </p>
              <br />
            </li>
            <li>
              <h3>ACCESS AND SERVICE RESTRICTIONS</h3>

              <p>
                <br />
                You agree that our Platform, including but not limited to the Website, graphics, trademarks, and editorial content, contains proprietary content, information, and material, which are owned by intentX and/or our licensors, including our customers, brands and agencies, and are protected by applicable intellectual property and other laws, including but not limited to copyright. You agree that you will not use such proprietary content, information or materials other than for your permitted use of our Platform or in any manner that is inconsistent with the terms contained in this Agreement.
              </p>
              <br />
              <p>
                You agree not to modify, rent, lease, loan, sell, distribute, or create derivative works based on our Website or Platform, in any manner, and you will not exploit our Website or Platform in any unauthorized way whatsoever, including but not limited to, using our Website or Platform to transmit any computer viruses, worms, Trojan horses or other malware, or by trespassing or burdening network capacity. You further agree not to use our Website or Platform in any manner to harass, abuse, stalk, threaten, defame or otherwise infringe or violate the rights of any other party, and that we are not in any way responsible for any such use by you, nor for any harassing, threatening, defamatory, offensive, infringing or illegal messages or transmissions that you may receive as a result of using our Website or Platform.
              </p>
              <br />
            </li>
            <li>
              <h3>RESERVATION OF RIGHTS</h3>

              <p>
                <br />
                You acknowledge and agree that our Website and Platform are provided for your use. Except to the extent necessary to access and use our Website or Platform, nothing in this Agreement grants any title or ownership interest in or to any copyrights, patents, trademarks, trade secrets or other proprietary rights in or relating to our Website or Platform, whether expressly, by implication, estoppel, or otherwise. intentX and its licensors and service providers reserve and will retain their entire right, title, and interest in and to our Website and Platform, including all copyrights, trademarks, and other intellectual property rights therein or relating thereto, except as expressly granted to you in this Agreement.
              </p>
              <br />
            </li>
            <li>
              <h3>ACCESS RIGHTS</h3>

              <p>
                <br />
                You can access and use our Website at
                {' '}
                <a href="https://www.intentx.com">www.intentx.com</a>
                . You agree that we have the right to disable your access and use rights, at any time if, in our opinion, you have violated any provision of this Agreement and/or our
                {' '}
                <a href="/privacy-policy">Privacy Policy</a>
                .  You agree to cooperate with us if the security of our Website or Platform is compromised by you or another person through the use of our Website or Platform.  We will not be liable for any loss or damage arising from your failure to comply with this Section.
              </p>
              <br />
              <p>
                We collect personal and business information (as set forth in our
                {' '}
                <a href="/privacy-policy">Privacy Policy</a>
                ), which we need, from you when you register to use our Website or Platform. This information is necessary for us to provide our Website or Platform to you and is stored on our servers to enable us to continue to provide our Website or Platform to you. Upon your written request, we will provide you with a list of all of the Personal Information that we store on you within sixty (60) days of receiving your request. Also, upon your prior written request, we will delete any such information within sixty (60) days of receiving your request. Notwithstanding, please note that, if you ask us to delete all such information, we will not be able to continue to provide our Website or Platform to you. Please send your requests to us at
                {' '}
                <a href="mailto:contact@intentx.com">contact@intentx.com</a>
                .
              </p>
              <br />
            </li>
            <li>
              <h3>REQUIRED CONDUCT AND PROHIBITED CONDUCT</h3>

              <p>
                <br />
                As a condition to access our Website or Platform, you agree to this Agreement and to strictly observe the following:

                <ol className="ten-main-list">
                  <br />
                  <li style={{ listStyleType: 'lower-alpha' }}>
                    <p style={{ display: 'inline-block', paddingLeft: 5 }}>Required Conduct</p>
                    <br />
                    <ol style={{ listStylePosition: 'outside', paddingLeft: 40 }}>
                      <br />
                      <li style={{ listStyleType: 'lower-roman' }}>
                        Comply with all applicable laws, including, without limitation, tax laws, export control laws and regulatory requirements
                      </li>
                      <li style={{ listStyleType: 'lower-roman' }}>
                        Provide accurate information to intentX and update from time to time as may be necessary
                      </li>
                      <li style={{ listStyleType: 'lower-roman' }}>
                        Review our
                        {' '}
                        <a href="/privacy-policy">Privacy Policy</a>
                      </li>
                      <li style={{ listStyleType: 'lower-roman' }}>
                        Review and comply with notices sent by intentX
                      </li>
                    </ol>
                  </li>
                  <br />
                  <li style={{ listStyleType: 'lower-alpha' }}>
                    <p style={{ display: 'inline-block', paddingLeft: 5 }}>Prohibited Conduct</p>
                    <ol style={{ listStylePosition: 'outside', paddingLeft: 40 }}>
                      <br />
                      <li style={{ listStyleType: 'lower-roman' }}>
                        Duplicate, license, sublicense, publish, broadcast, transmit, distribute, perform, display, sell, rebrand, otherwise transfer or commercially exploit our Website or Platform (excluding any user content)
                      </li>
                      <li style={{ listStyleType: 'lower-roman' }}>
                        Reverse engineer, decompile, disassemble, decipher, capture screen shots, or otherwise attempt to derive the source code for any underlying intellectual property used to provide our Website or Platform, or any part thereof
                      </li>
                      <li style={{ listStyleType: 'lower-roman' }}>
                        Utilize information, content or any data you view on and/or obtain from our Website or Platform to provide any service that is competitive with us
                      </li>
                      <li style={{ listStyleType: 'lower-roman' }}>
                        Imply or state, directly or indirectly, that you are affiliated with or endorsed by intentX unless you have entered into a written agreement with us
                      </li>
                      <li style={{ listStyleType: 'lower-roman' }}>
                        Adapt, modify, or create derivative works based on our Website, App, or Platform or technology underlying our Website or Platform, or other users’ content, in whole or in part
                      </li>
                      <li style={{ listStyleType: 'lower-roman' }}>
                        Rent, lease, loan, trade, sell/re-sell access to our Website or Platform or any information therein, or the equivalent, in whole or part
                      </li>
                      <li style={{ listStyleType: 'lower-roman' }}>
                        Access, reload, or “refresh” or make any other request to transactional servers that are beyond generally accepted usage of web-based applications
                      </li>
                      <li style={{ listStyleType: 'lower-roman' }}>
                        Use manual or automated software, devices, scripts robots, other means or processes to “scrape”, “crawl” or “spider” any web pages contained in the Website
                      </li>
                      <li style={{ listStyleType: 'lower-roman' }}>
                        Use automated methods to add contacts or send messages
                      </li>
                      <li style={{ listStyleType: 'lower-roman' }}>
                        Engage in “framing”, “mirroring”, or otherwise simulating the appearance or function of our Website
                      </li>
                      <li style={{ listStyleType: 'lower-roman' }}>
                        Attempt to or actually access our Website or Platform by any means other than through the interface provided by intentX
                      </li>
                      <li style={{ listStyleType: 'lower-roman' }}>
                        Attempt to or actually override any security component included in or underlying our Website or Platform
                      </li>

                      <li style={{ listStyleType: 'lower-roman' }}>
                        Engage in any action that interferes with the proper working of or places an unreasonable load on our infrastructure, including but not limited to unsolicited communications, attempts to gain unauthorized access, or transmission or activation of computer viruses
                      </li>
                      <li style={{ listStyleType: 'lower-roman' }}>
                        Remove any copyright, trademark, or other proprietary rights notices contained in or on our Website or Platform, including those of both intentX or any of our licensors
                      </li>
                      <li style={{ listStyleType: 'lower-roman' }}>
                        Use any information obtained from our Website or Platform to harass, abuse, or harm another user
                      </li>
                      <li style={{ listStyleType: 'lower-roman' }}>
                        Engage in any action or promote any content that is harmful, offensive, illegal, unlawful, discriminatory, dangerous, profane, or abusive.
                      </li>
                    </ol>
                  </li>
                </ol>
              </p>
              <br />
            </li>
            <li>
              <h3>INTENTX COMMUNICATIONS</h3>

              <p>
                <br />
                You understand and agree that you may receive information and notifications from intentX via email or through our Platform.  You hereby consent to receive communications via email or our Platform. You acknowledge that you may incur additional charges or fees from your wireless provider for these communications, including data usage fees, and you acknowledge and agree that you are solely responsible for any such charges and fees and not intentX.
              </p>
              <br />
              <p>
                <ol style={{ listStylePosition: 'outside', paddingLeft: 40 }} className="eleven-list">
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    <p>
                      <span style={{ textDecoration: 'underline' }}>Email Contact</span>
                      . We may send promotional messages about us and our products and services related to our Website and Platform to your email.  When you send us a query email at
                      {' '}
                      <a href="mailto:contact@intentx.com">contact@intentx.com</a>
                      , you are providing us with consent to send emails to you for replying to your queries at your provided email address. By providing your email address, you agree with these Terms of Service and our
                      {' '}
                      <a href="/privacy-policy">Privacy Policy</a>
                      .
                    </p>
                  </li>
                  <br />
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    <p>
                      <span style={{ textDecoration: 'underline' }}>Push Notification</span>
                      .  You can opt out of receiving push notifications through your device settings. Please note that opting out of receiving push notifications may impact your use of our Website and Platform.
                    </p>
                  </li>
                </ol>
              </p>
              <br />
            </li>
            <li>
              <h3>PAYMENT</h3>

              <p>
                <br />
                Our Website and Platform are currently provided to you at no cost.  In the event that we change this in the future, we will communicate such modification to you as per the procedure detailed under
                {' '}
                <a href="#twenty" style={{ textDecoration: 'underline' }}>Section 20</a>
                {' '}
                or as otherwise specified in another agreement between intentX and you.
              </p>
              <br />
            </li>
            <li>
              <h3>INDEMNIFICATION</h3>

              <p>
                <br />
                You agree to indemnify, defend, and hold intentX and our officers, employees, managers, directors, customers, and agents (the “
                <span className="underlined">Indemnitees</span>
                ”) harmless from and against any and all costs, liabilities, losses and expenses (including but not limited to reasonable attorneys’ fees) resulting from any claim, suit, action, demand or proceeding brought by any third party against intentX and our Indemnitees arising from any of the following: (i) a breach of this Agreement; (ii) the negligence, fraud, or willful misconduct of you or your employees, agents, or contractors; (iii) incorrect information provided by you in your account or elsewhere; or (iv) a failure by you or your employees, agents, contractors or invitees to comply with applicable laws and regulations.
              </p>
              <br />
            </li>
            <li>
              <h3>
                DISCLAIMERS
              </h3>

              <p>
                <br />
                Your access to and use of our Website and Platform or any content are at your own risk. You understand and agree that our Website and Platform are provided to you on an “AS IS” and “AS AVAILABLE” basis. Without limiting the foregoing, to the maximum extent permitted under applicable law, WE DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. We make no warranty or representation and disclaim all responsibility and liability for: (i) the completeness, accuracy, availability, timeliness, security or reliability of our Website or Platform or any content; (ii) any harm to your computer system, loss of data, or other harm that results from your access to or use of our Website or Platform or any content; (iii) the deletion of, or the failure to store or to transmit, any content and other communications maintained by our Website or Platform; and (iv) whether our Website or Platform will meet your requirements or be available on an uninterrupted, secure, or error-free basis. No advice or information, whether oral or written, obtained from us or through our Website or Platform, will create any warranty or representation not expressly made herein.
              </p>
              <br />
            </li>
            <li>
              <h3>LIMITATION OF LIABILITY</h3>

              <p>
                <br />
                You acknowledge and agree that, in no event will intentX be liable to you or any third party for any indirect, punitive, exemplary, incidental, special, or consequential damages whether in contract, tort (including negligence), or otherwise arising out of this Agreement, or the use of, or the inability to use, our Website or Platform, including, without limitation, any information made available through our Website or Platform pursuant to this Agreement. In the event the foregoing limitation of liability is determined by a court of competent jurisdiction to be unenforceable, then the maximum liability for all claims of every kind will not exceed one times (1x) the aggregate of payments received under this Agreement. The foregoing limitation of liability will cover, without limitation, any technical malfunction, computer error or loss of data, and any other injury arising from the use of our Website or Platform.  Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. To the extent that intentX may not disclaim any implied warranty or limit its liabilities, the scope and duration of such warranty and the extent of intentX’s liability will be the minimum permitted under applicable law.
              </p>
              <br />
            </li>
            <li>
              <h3>TERMINATION</h3>

              <p>
                <br />
                You may terminate this binding legal Agreement with intentX by providing thirty (30) days prior written notice, with a possible termination charge.
                {' '}
              </p>
              <br />
              <p>
                We reserve the right to suspend or terminate your account or cease providing you with access to all or part of our Website or Platform at any time for any or no reason, including, but not limited to, if we reasonably believe: (i) you have violated this Agreement or our
                {' '}
                <a href="/privacy-policy">Privacy Policy</a>
                , (ii) you create risk or possible legal exposure for intentX; or (iii) our provision of our Website or Platform to you is no longer commercially viable. We will make reasonable efforts to notify you of such termination by the email address associated with your account or the next time you attempt to access your account, depending on the circumstances. In all such cases, this Agreement shall terminate, including, without limitation, your license to use our Website or Platform.
              </p>
              <br />
              <p>
                All sections, which by their nature and context are intended to survive the termination of this Agreement, will survive.
              </p>
              <br />
            </li>
            <li>
              <h3>COPYRIGHT INFRINGEMENT/DMCA NOTICE</h3>

              <p>
                <br />
                If you believe that any content on our Website or Platform violates your copyright, and you wish to have the allegedly infringing material removed, the following information in the form of a written notification (pursuant to the Digital Millennium Copyright Act of 1998 (“
                <span className="underlined">DMCA Takedown Notice</span>
                ”)) must be provided to our designated Copyright Agent.
                {' '}
                <ol className="seventen-list" style={{ listStylePosition: 'outside', paddingLeft: 40 }}>
                  <br />
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    Your physical or electronic signature
                  </li>
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    Identification of the copyrighted work(s) that you claim to have been infringed
                  </li>
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    Identification of the material on our Website or Platform that you claim is infringing and that you request us to remove
                  </li>
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    Sufficient information to permit us to locate such material
                  </li>
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    Your address, telephone number, and email address
                  </li>
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    A statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law
                  </li>
                  <li style={{ listStyleType: 'lower-alpha', paddingLeft: 10 }}>
                    A statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner.
                  </li>
                </ol>
              </p>
              <br />
              <p>
                intentX’s Copyright Agent to receive DMCA Takedown Notices is intentX Privacy Team, at
                {' '}
                <a href="mailto:privacy@intentx.com">privacy@intentx.com</a>
                {' '}
                and at intentX Inc., Attn: DMCA Notice, 10880 Wilshire Blvd., Suite 1101, Los Angeles, California 90024. You acknowledge that for us to be authorized to take down any content, your DMCA Takedown Notice must comply with all the requirements of this Section. Please note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation of material fact (falsities) in a written notification automatically subjects the complaining party to liability for any damages, costs and attorney’s fees incurred by intentX in connection with the written notification and allegation of copyright infringement.
              </p>
              <br />
            </li>
            <li>
              <h3>ASSIGNMENT</h3>

              <p>
                <br />
                This Agreement is only for your benefit. You shall have no right to assign this Agreement or any benefits or obligation hereunder to any other party or legal entity. Any attempted assignment shall be void.
              </p>
              <br />
            </li>
            <li>
              <h3>ANTI-BRIBERY AND EXPORT COMPLIANCE</h3>

              <p>
                <br />
                You agree not to promote, approach, use, distribute, transfer, provide, sub-license, share with, or otherwise offer our Website or Platform in violation of any laws or this Agreement, including, without limitation, the United States Foreign Corrupt Practices Act, the UK Bribery Act and similar anti-corruption statutes in all jurisdictions. Without limiting the foregoing, you will not knowingly directly or indirectly export, re-export, transfer, make available or release (collectively, “
                <span className="underlined">Export</span>
                ”) our Website or Platform to any destination, person, entity or end-use prohibited or restricted under the US law without prior US government authorization to the extent required by the applicable export control regulations, including without limitation, to any parties listed on any of the denied parties lists or specially designated nationals lists maintained under the Export Administration Regulations or the Security, and the Foreign Asset Control Regulations (31 CFR 500 et seq.) administered by the US Department of Treasury, Office of Foreign Assets Control without appropriate US government authorization to the extent required by the applicable regulations.
              </p>
              <br />
            </li>
            <li id="twenty">
              <h3>MODIFICATIONS</h3>

              <p>
                <br />
                We reserve the right, at our sole discretion, to change or modify this Agreement at any time. In the event, we modify the terms of this Agreement, such modifications shall be binding on you only upon your acceptance of the modified Agreement. We will inform you about the modifications on our Website or Platform by posting a modified version of this page, or by a comparable means within a reasonable time period.  Your continued use of our Website or Platform shall constitute your consent to such changes.
              </p>
              <br />
            </li>
            <li>
              <h3>RELATIONSHIP OF PARTIES</h3>

              <p>
                <br />
                The parties hereto are independent contractors, and nothing contained herein shall be interpreted as creating any relationship other than that of independent contracting parties. The parties shall not be construed as being partners, joint ventures, shareholders, employer/employee, or agent/servant. The User has no power or authority to bind intentX to any obligation, agreement, debt or liability. The User shall not hold itself out as an agent or representative of intentX.
              </p>
              <br />
            </li>
            <li id="twenty-two">
              <h3>
                GOVERNING LAW
              </h3>

              <p>
                <br />
                This Agreement shall be governed by the law of the State of Delaware, without respect to its conflicts of laws principles. Each of the parties to this Agreement consents to the exclusive jurisdiction and venue of the state and federal courts located in Los Angeles County, California for any actions not subject to Dispute Resolution and Arbitration provisions as set forth in
                {' '}
                <a href="#twenty-three" style={{ textDecoration: 'underline' }}>Section 23</a>
                .
              </p>
              <br />
            </li>
            <li id="twenty-three">
              <h3>DISPUTE RESOLUTION AND ARBITRATION</h3>

              <p>
                <br />
                PLEASE READ THE FOLLOWING SECTION CAREFULLY BECAUSE IT REQUIRES YOU TO ARBITRATE CERTAIN DISPUTES AND CLAIMS WITH INTENTX AND LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM US.
              </p>
              <br />
              <ol style={{ listStylePosition: 'inside', paddingLeft: 40 }} className="twenty-three-list">
                <li style={{ listStyleType: 'lower-alpha' }}>
                  <p style={{ display: 'inline-block', paddingLeft: 10 }}>Binding Arbitration</p>

                  <p>
                    <br />
                    Except for any disputes, claims, suits, actions, causes of action, demands or proceedings (collectively, “
                    <span className="underlined">Disputes</span>
                    ”) in which either party seeks to bring an individual action in small claims court or seeks injunctive or other equitable relief for the alleged unlawful use of intellectual property, including, without limitation, copyrights, trademarks, trade names, logos, trade secrets or patents, you and intentX agree (a) to waive your and intentX’s respective rights to have any and all Disputes arising from or related to this Agreement or the use of our Website or Platform, resolved in a court, and (b) to waive your and intentX’s respective rights to a jury trial. Instead, you and intentX agree to arbitrate Disputes through binding arbitration (which is the referral of a Dispute to one or more persons charged with reviewing the Dispute and making a final and binding determination to resolve it instead of having the Dispute decided by a judge or a jury in court).
                  </p>
                  <br />
                </li>

                <li style={{ listStyleType: 'lower-alpha' }}>
                  <p style={{ display: 'inline-block', paddingLeft: 10 }}>No Class Arbitrations, Class Actions or Representative Actions</p>
                  <p>
                    <br />
                    You and intentX agree that any Dispute arising out of or related to these Terms of Service or use or access of our Website or Platform is personal to you and intentX and that such Dispute will be resolved solely through individual arbitration and will not be brought as a class arbitration, class action or any other type of representative proceeding. You and intentX agree that there will be no class arbitration or arbitration in which an individual attempts to resolve a Dispute as a representative of another individual or group of individuals. Further, you and intentX agree that a Dispute cannot be brought as a class or other type of representative action, whether within or outside of arbitration, or on behalf of any other individual or group of individuals.
                  </p>
                  <br />
                </li>

                <li style={{ listStyleType: 'lower-alpha' }}>
                  <p style={{ display: 'inline-block', paddingLeft: 10 }}>Federal Arbitration Act</p>
                  <p>
                    <br />
                    You and intentX agree that these Terms of Service affect interstate commerce and that the enforceability of this Section shall be both substantively and procedurally governed by and construed and enforced in accordance with the Federal Arbitration Act, 9 U.S.C. § 1 et seq. (the “FAA”), to the maximum extent permitted by applicable law.
                  </p>
                  <br />
                </li>

                <li style={{ listStyleType: 'lower-alpha' }}>
                  <p style={{ display: 'inline-block', paddingLeft: 10 }}>Notice; Informal Dispute Resolution</p>
                  <p>
                    <br />
                    You and intentX agree that each party will notify the other party in writing of any arbitral or small claims Dispute within thirty (30) days of the date it arises, so that the parties can attempt in good faith to resolve the Dispute informally. Notice to intentX shall be sent by certified mail or courier to intentX Inc., Attn: intentX Privacy Team, 10880 Wilshire Blvd., Suite 1101, Los Angeles, California 90024. Your notice must include (a) your name, postal address, telephone number, the email address you use or used for your intentX account and, if different, an email address at which you can be contacted, (b) a description in reasonable detail of the nature or basis of the Dispute, and (c) the specific relief that you are seeking. Our notice to you will be sent electronically in accordance with this Agreement and will include (x) our name, postal address, telephone number and an email address at which we can be contacted with respect to the Dispute, (y) a description in reasonable detail of the nature or basis of the Dispute, and (z) the specific relief that we are seeking. If you and intentX cannot agree how to resolve the Dispute within thirty (30) days after the date notice is received by the applicable party, then either you or intentX may, as appropriate and in accordance with this Section, commence an arbitration proceeding.
                  </p>
                  <br />
                </li>

                <li style={{ listStyleType: 'lower-alpha' }}>
                  <p style={{ display: 'inline-block', paddingLeft: 10 }}>Process</p>
                  <p>
                    <br />
                    EXCEPT FOR DISPUTES IN WHICH EITHER PARTY SEEKS TO BRING AN INDIVIDUAL ACTION IN SMALL CLAIMS COURT OR SEEKS INJUNCTIVE OR OTHER EQUITABLE RELIEF FOR THE ALLEGED UNLAWFUL USE OF INTELLECTUAL PROPERTY, INCLUDING, WITHOUT LIMITATION, COPYRIGHTS, TRADEMARKS, TRADE NAMES, LOGOS, TRADE SECRETS OR PATENTS, YOU AND INTENTX AGREE THAT ANY DISPUTE MUST BE COMMENCED OR FILED BY YOU OR INTENTX WITHIN (1) YEAR OF THE DATE THE DISPUTE AROSE, OTHERWISE THE UNDERLYING CLAIM IS PERMANENTLY BARRED (WHICH MEANS THAT YOU AND INTENTX WILL NO LONGER HAVE THE RIGHT TO ASSERT SUCH CLAIM REGARDING THE DISPUTE). You and intentX agree that (a) any arbitration will occur in Los Angeles County, California, (b) arbitration will be conducted confidentially by a single arbitrator in accordance with the Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes (the “AAA Rules”) then in effect, except as modified by this “Dispute Resolution” section, and (c) that the state or federal courts of the State of California, have exclusive jurisdiction over any appeals and the enforcement of an arbitration award. You may also litigate a Dispute in the small claims court located in the county of your billing address if the Dispute meets the requirements to be heard in small claims court.
                  </p>
                  <br />
                </li>

                <li style={{ listStyleType: 'lower-alpha' }}>
                  <p style={{ display: 'inline-block', paddingLeft: 10 }}>Authority of Arbitrator</p>
                  <p>
                    <br />
                    As limited by the
                    {' '}
                    <span className="underlined">FAA</span>
                    , these Terms of Service and the applicable
                    {' '}
                    <span className="underlined">AAA Rules</span>
                    , the arbitrator will have (a) the exclusive authority and jurisdiction to make all procedural and substantive decisions regarding a Dispute, including the determination of whether a Dispute is arbitral, and (b) the authority to grant any remedy that would otherwise be available in court; provided, however, that the arbitrator does not have the authority to conduct a class arbitration or a representative action, which is prohibited by these Terms of Service. The arbitrator may only conduct an individual arbitration and may not consolidate more than one individual’s claims, preside over any type of class or representative proceeding, or preside over any proceeding involving more than one individual. Notwithstanding anything to the contrary herein or the applicable AAA Rules, discovery in the arbitration shall be limited to one set of interrogatories, one set of requests for admissions, and one set of requests for production of documents.
                  </p>
                  <br />
                  <p>The arbitrator’s award of damages must be consistent with the terms of the “Limitation of Liability” section above as to the types and amounts of damages for which a party may be held liable. The arbitrator may award declaratory or injunctive relief only in favor of the claimant and only to the extent necessary to provide relief warranted by the claimant’s individual claim. If you prevail in arbitration you will be entitled to an award of attorneys’ fees and expenses, to the extent provided under applicable law. We will not seek and hereby waive all rights we may have under applicable law to recover, attorneys’ fees and expenses if we prevail in arbitration.</p>
                  <br />
                </li>

                <li style={{ listStyleType: 'lower-alpha' }}>
                  <p style={{ display: 'inline-block', paddingLeft: 10 }}>Rules of AAA </p>
                  <p>
                    <br />
                    The AAA Rules are available at
                    {' '}
                    <a href="https://www.adr.org/Rules">https://www.adr.org/Rules</a>
                    {' '}
                    or by calling the AAA at 1-800-778-7879. By agreeing to be bound by these Terms of Service, you either (a) acknowledge and agree that you have read and understand the rules of AAA, or (b) waive your opportunity to read the rules of AAA and any claim that the rules of AAA are unfair or should not apply for any reason.
                  </p>
                  <br />
                </li>

                <li style={{ listStyleType: 'lower-alpha' }}>
                  <p style={{ display: 'inline-block', paddingLeft: 10 }}>Severability</p>

                  <p>
                    <br />
                    If any term, clause or provision of this Section is held invalid or unenforceable, it will be so held to the minimum extent required by law, and all other terms, clauses and provisions of this Section will remain valid and enforceable. Further, the waivers set forth herein are severable from the other provisions of this Agreement and will remain valid and enforceable, except as prohibited by applicable law.
                  </p>
                  <br />
                </li>

                <li style={{ listStyleType: 'lower-alpha' }}>
                  <p style={{ display: 'inline-block', paddingLeft: 10 }}>Opt-Out Right</p>

                  <p>
                    <br />
                    YOU HAVE THE RIGHT TO OPT OUT OF BINDING ARBITRATION WITHIN THIRTY (30) DAYS OF THE DATE YOU FIRST ACCEPTED THE TERMS OF THIS SECTION BY WRITING TO: INTENTX INC., RE: OPT-OUT, 10880 Wilshire Blvd., Suite 1101, Los Angeles, California 90024. IN ORDER TO BE EFFECTIVE, THE OPT OUT NOTICE MUST INCLUDE YOUR FULL NAME AND CLEARLY INDICATE YOUR INTENT TO OPT OUT OF BINDING ARBITRATION. BY OPTING OUT OF BINDING ARBITRATION, YOU ARE AGREEING TO RESOLVE DISPUTES IN ACCORDANCE WITH
                    {' '}
                    <a href="#twenty-two" style={{ textDecoration: 'underline' }}>SECTION 22</a>
                    .
                  </p>
                  <br />
                </li>
              </ol>
              <br />
            </li>
            <li>
              <h3>MISCELLANEOUS</h3>
              <p>
                <br />
                This Agreement along with our
                {' '}
                {' '}
                <a href="/privacy-policy">Privacy Policy</a>
                {' '}
                constitutes the entire agreement between you and intentX and supersedes any prior agreements between you and intentX with respect to the subject matter herein. Our failure to exercise or enforce any right or provision of this Agreement will not constitute a waiver of such right or provision. If any provision of this Agreement is found by a court of competent jurisdiction to be invalid, we both nevertheless agree that the court should endeavor to give effect to our intentions as reflected in this provision, and the other provisions of this Agreement to remain in full force and effect. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of our Website or Platform or this Agreement must be filed within one (1) year after such claim or cause of action arose or be forever barred. A party’s failure to act with respect to a breach by the other party does not constitute a waiver of the party’s right to act with respect to subsequent or similar breaches. All the sections intended to survive the termination of this Agreement shall survive. The section titles in this Agreement are for convenience only and have no legal or contractual effect. Except as explicitly stated otherwise, any notices to intentX shall be given by certified mail, postage prepaid and return receipt requested to intentX Inc. at 10880 Wilshire Blvd., Suite 1101, Los Angeles, California 90024. Any notices to you shall be provided to you through our Website or Platform or given to you via the email address or physical address you provide to intentX during the registration process.
              </p>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
TermsOfService.propTypes = {
  user: PropTypes.object,
};
