import React from 'react';
// import Button from '../../common/Button';
import "./index.css"

// import styles from "react-responsive-carousel/lib/styles/carousel.min.css";

const Reviwes = ({m}) => {

  // const review = [
  //   {
  //     image:'',
  //     text:'intentX provides increased engagement and has exceeded expectations in revenue, and will continue to play a key role in our product recommendation strategy.',
  //     author: 'Greg Morrow, The Enthusiast Network'
  //   },
  //   {
  //     image:'',
  //     text:'intentX provides increased engagement and has exceeded expectations in revenue, and will continue to play a key role in our product recommendation strategy.',
  //     author: 'Greg Morrow, The Enthusiast Network'
  //   },
  //   {
  //     image:'',
  //     text:'intentX provides increased engagement and has exceeded expectations in revenue, and will continue to play a key role in our product recommendation strategy.',
  //     author: 'Greg Morrow, The Enthusiast Network'
  //   },
  //   {
  //     image:'',
  //     text:'intentX provides increased engagement and has exceeded expectations in revenue, and will continue to play a key role in our product recommendation strategy.',
  //     author: 'Greg Morrow, The Enthusiast Network'
  //   }
  // ];
  return (
      <>
<div className="container">
<div className="row">
<ul>
  <li className="discover">
    <div className="dis-txt">
      {/* <header><h3>{m.disxthead}</h3></header> */}
      <div className="xtpor">
        <b>{m.disxtshead}</b>
      <p>{m.text}</p>
      </div>
      </div>
    <div className="dis-img" >
    <img src={m.image} alt="firstsection"  style={{width: '100%', height: '100%'}}/>
      </div>
  </li>

</ul>
</div>
</div>
</>
  );
}

export default Reviwes;