import React from 'react';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
// import waves1 from '../../staticassets/contact/background/backgroundwaves1@2x.png';
import "./index.css"
import FirstSection from '../../components/contactadvisor/FirstSection';
import SecoundSection from '../../components/contactadvisor/SecoundSection';

const ContactAdvisor = () => {
  return (
    <>
<Header />
    <div className="contact-advisor">
{/* <div className="topimg"><img src={waves1} alt="waves1" />
</div> */}

    <FirstSection />
      <div className="container">
    <SecoundSection />
    </div>
    </div>

<Footer />

    </>
  );
}

export default ContactAdvisor;