import React from 'react';
import "./index.css"

const FirstSection = () => {
  return (
    <>
      <section className="topsection">
        <div className="container">

          <article className="bannersection">
            <div className="row">
              <div className="col-12  one">
                <h1> Interested In An Advertiser Partnership? </h1>
              </div >
              <div className="col-12  one">
                <h1>  - Contact Us  </h1>
              </div >
            </div>
          </article>
        </div>

      </section>
      <div className="svgflip2" style={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
        <svg style={{ position: 'relative', top: '-1px' }} version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 105" width="1920" height="105">
          <path fill='#dae6f4' id="Trazado_2028" d="m0 0h1920v105c0 0-53.1-0.7-177.2-35.9-124.2-35.3-168.5 22.1-432.2 18.9-263.6-3.2-420.3-39.5-622.4-31.9-178.3 6.7-317 48.2-476 45.9-171-2.3-212.2-9-212.2-9z" />
          <path fill='#305ab9' id="Trazado_2029" d="m-3-2h1927v65.7c0 0-157.9 19.1-324-9.2-166.2-28.4-374-25.6-744.3 20.9-370.4 46.4-858.7-33.9-858.7-33.9z" />
        </svg>
      </div>
    </>
  );
}

export default FirstSection;