import React from 'react';
// import Button from '../../common/Button';
import "./index.css"
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
// import Closed from '@material-ui/icons/ClearOutlined';


// import styles from "react-responsive-carousel/lib/styles/carousel.min.css";

const Reviwes = ({ m }) => {

  // const review = [
  //   {
  //     image:'',
  //     text:'intentX provides increased engagement and has exceeded expectations in revenue, and will continue to play a key role in our product recommendation strategy.',
  //     author: 'Greg Morrow, The Enthusiast Network'
  //   },
  //   {
  //     image:'',
  //     text:'intentX provides increased engagement and has exceeded expectations in revenue, and will continue to play a key role in our product recommendation strategy.',
  //     author: 'Greg Morrow, The Enthusiast Network'
  //   },
  //   {
  //     image:'',
  //     text:'intentX provides increased engagement and has exceeded expectations in revenue, and will continue to play a key role in our product recommendation strategy.',
  //     author: 'Greg Morrow, The Enthusiast Network'
  //   },
  //   {
  //     image:'',
  //     text:'intentX provides increased engagement and has exceeded expectations in revenue, and will continue to play a key role in our product recommendation strategy.',
  //     author: 'Greg Morrow, The Enthusiast Network'
  //   }
  // ];
  return (
    <>
      <div className="container">
        <div className="row">
          <ul>

            <li>
              <div>
                <img src={m.image} alt="firstsection" style={{ width: '308', height: '130' }} />

              </div>
              <div>
                <p>
                  <FormatQuoteIcon style={{ transform: "rotate(180deg)" }} />intent<sup>x</sup> {m.text} <FormatQuoteIcon />
                </p>
                <span>{m.author}</span>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </>
  );
}

export default Reviwes;