import React from 'react';
// import Button from '../../common/Button';
import "./index.css"
import Reviwes from './Reviwes';
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import tten from "../../../staticassets/homepage/sixth/logos/TEN@2x.png";


const Home = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  const review = [
    {
      image: tten,
      text: 'provides increased engagement and has exceeded expectations in revenue, and will continue to play a key role in our product recommendation strategy.',
      author: 'Greg Morrow, The Enthusiast Network'
    },
    {
      image: tten,
      text: 'provides increased engagement and has exceeded expectations in revenue, and will continue to play a key role in our product recommendation strategy.',
      author: 'Greg Morrow, The Enthusiast Network'
    },
    {
      image: tten,
      text: 'provides increased engagement and has exceeded expectations in revenue, and will continue to play a key role in our product recommendation strategy.',
      author: 'Greg Morrow, The Enthusiast Network'
    },
    {
      image: tten,
      text: 'provides increased engagement and has exceeded expectations in revenue, and will continue to play a key role in our product recommendation strategy.',
      author: 'Greg Morrow, The Enthusiast Network'
    }
  ];
  return (
    <>
      <div className="carousal1sect ">
        {/* <div className="bckimgcarosal1"><img src={backgroundwaves3} alt="yellowwaves" height="500px" /></div> */}
        <Carousel
          ssr
          partialVisbile
          showDots={true}
          swipeable={true}
          arrows={false}
          // deviceType={deviceType}
          itemClass="image-item2"
          containerClass="carousel-container"
          responsive={responsive}
        >
          {review.map(rew => {
            return (
              <div className="carosoulbsec">
                <Reviwes m={rew} />
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
}

export default Home;