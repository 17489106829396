import React from 'react';
import "./index.css"
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import fandom from '../../../../src/staticassets/publisher/fifth/brands/fandom.jpg';
// import accuweather from '../../../../src/staticassets/publisher/fifth/brands/accuweather.jpg';
// import laptopdashboard from '../../../../src/staticassets/publisher/sixth/laptopdashboard@2x.png';
// import laptopdashboard from '../../../../src/staticassets/publisher/sixth/laptopdashboard@2x.png';
import SixthSection from '../../Home/SixthSection';

const Home = () => {

  // const responsive = {
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 2,
  //     slidesToSlide: 1 // optional, default to 1.
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 2,
  //     slidesToSlide: 1 // optional, default to 1.
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //     slidesToSlide: 1 // optional, default to 1.
  //   }
  // };
  // const data = [
  //   {
  //     image:fandom,
  //     text: 'intentX has helped us increase incremental revenue, boost views, and extend to ecommerce quickly at scale. The team is responsive and easy to work with.',
  //     username: 'Jhone Doe',
  //     designation:'C.E.O of Metro'
  //   },
  //   {
  //     image:fandom,
  //     text: 'The intentX platform has been a major part in our ecommerce initiative. It was up and running quickly with easy, seamless integration.',
  //     username: 'Jhone Doe',
  //     designation:'C.E.O of Metro'
  //   },
  //   {
  //     image:fandom,
  //     text: 'intentX has helped us increase incremental revenue, boost views, and extend to ecommerce quickly at scale. The team is responsive and easy to work with.',
  //     username: 'Jhone Doe',
  //     designation:'C.E.O of Metro'
  //   },
  //   {
  //     image:fandom,
  //     text: 'The intentX platform has been a major part in our ecommerce initiative. It was up and running quickly with easy, seamless integration.',
  //     username: 'Jhone Doe',
  //     designation:'C.E.O of Metro'
  //   }
  // ];
  return (
    <>
      <section className="pub-fifth">
        {/* <div className="massive-ywaves1"><img src={backgroundwaves} alt="yellowwaves"style={{ width: '100%'}} /></div> */}
        <div className="svgflip2" style={{ marginBottom: '-5px', width: '100%' }}>
          <svg style={{ position: 'relative', top: 1 }} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1920 122.978"><path id="Trazado_2013" data-name="Trazado 2013" d="M-1.489,24.244s86.441-7.222,219.764,1.533S811.592,150.87,1030.661,129.387s537.776-76.824,706.595-83.079,181.253-8.228,181.253-8.228V143.444h-1920Z" transform="translate(1.491 -21.445)" fill="#d9ebf6"></path><path id="Trazado_2012" data-name="Trazado 2012" d="M-1.491.035s102.9-1.411,349.288,25.12S726.811,76.594,992.1,76.594s368.48-31.787,625.261-45.892,301.144-15.315,301.144-15.315v86.057h-1920Z" transform="translate(1.491 23.022)" fill="#305ab9"></path></svg>
        </div>
        <div className="pub-fifth-bck">
          <div className="container">
            <div className="pub-fifth-carousal">
              {/* <div className="topimage"><img src={laptopdashboard} alt="firstsection" style={{ width: '100%'}} /></div> */}
              {/* <Carousel
      ssr
      partialVisbile
      itemClass="image-item-pub"
      responsive={responsive}
    >
      {data.slice(0, 4).map(m => {
        return (
          <div className="carosoulbox">
              <div className="carosoulhead"><img src={m.image} width={158} height={52} alt="img" /></div>
              <div className="carosoultext">
                <p>{m.text}</p></div>
              <div className="carosouluserinfo">
                  <div className="carosoulusername">
                    <p>{m.username}</p>
                    <span>{m.designation}</span>
                  </div>
              </div>
          </div>
        );
      })}
    </Carousel> */}

              <SixthSection />
            </div>

          </div>
        </div>
        <div className="svgflip2" style={{ width: '100%' }}>
          <svg style={{ position: 'relative', top: -1 }} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1920 232.978"><path id="Trazado_2085" data-name="Trazado 2085" d="M-1.489,846h1920v218.029s-108.314,6.878-278.519,13.068c-45.615,1.659-102.05.721-143.021,0-112.32-1.977-125.22-2.041-262.81-13.068s-271.6-59.433-408.206-74.2S542.035,968.779,299.531,963-1.491,951.845-1.491,951.845Z" transform="translate(1.491 -846)" fill="#d9ebf6"></path><path id="Trazado_2086" data-name="Trazado 2086" d="M-1.491,804h1920V995.481s-58.6-.769-301.144-1.026-362.2,26.132-667.545,0S620.146,929.688,396,889.926-1.491,866.945-1.491,866.945Z" transform="translate(1.491 -805)" fill="#305ab9"></path></svg>
        </div>
      </section>
    </>
  );
}

export default Home;