import React from 'react';

import { NavLink } from 'react-router-dom';
import './index.css';
// import Close from '@mui/icons-material/Close';
import Closed from '@material-ui/icons/ClearOutlined';
import Dehaze from '@material-ui/icons/Dehaze';
import logo from '../../../staticassets/intentxlogo.svg';


const Header = () => {
    const [click, setClick] = React.useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);
  return (

         <div id="menu" >

     <div className={click ? "main-container" : ""}  onClick={()=>Close()} />
      <nav className="navbar" onClick={e => e.stopPropagation()}>
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            {/* <img src="https://intentx.com/wp-content/uploads/2018/05/logo_intentX-logomark-white.svg" alt="intentx" /> */}
            <img src={logo} alt="intentx" style={{width: '180px', height:"auto"}} />
          </NavLink>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/publishers"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Publishers
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/advertisers"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Advertisers
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/solutions"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                Solutions
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={click ? handleClick : null}
              >
                About Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
               onClick={click ? handleClick : null}
              >
                Contact Us
              </NavLink>
            </li>
            <li className="nav-item">
              {/* <NavLink
                exact
                to="/login"
                activeClassName="active"
                className="nav-links"
               onClick={()=>{window.open('https://dashboard.intentx.com', '_blank')}}
              >
              Login
              </NavLink> */}
              {/* <a href="https://dashboard.intentx.com" class="nav-links" rel="noreferrer noopener" target="_blank">Login</a> */}
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
              {click? <Closed /> : <Dehaze />}
              {/* <Dehaze /> */}
            {/* <i className={click ? <Close /> : <Dehaze />}></i> */}
          </div>
        </div>
      </nav>



{/* <NavLink
  exact
  to="/"
>
  Home
</NavLink>

<NavLink
  to="/publishers"
>
publishers
</NavLink> */}
    </div>
  );
}

export default Header;