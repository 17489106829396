import React from 'react';
import "./index.css"
import adspendinggraphic from '../../../staticassets/publisher/third/graph/AffiliateSpendinginBilliongraph@2x.png';
// import adrevenues from '../../../staticassets/publisher/secound/icons/adrevenues.png';
// import backgroundwaves from '../../../../src/staticassets/publisher/third/background/backgroundwaves2@2x.png';
import FourthSection from '../FourthSection';

const SecoundSection = () => {
  return (
      <>
<section className="pub-third">
{/* <div className="massive-ywaves1"><img src={backgroundwaves} alt="yellowwaves"style={{ width: '100%'}} /></div> */}
<div className="svgflip2" >
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1920 160.978">
<path id="Trazado_2009" data-name="Trazado 2009" d="M0,509H1920v40.136s-215.341-2.406-398.038,11.066-368.227-15.763-589.2,28.768-331.03,25.569-536.367,38S0,699,0,699Z" transform="translate(1920 670) rotate(180)" fill="#d5f4ff"/>
<path id="Trazado_2007" data-name="Trazado 2007" d="M0,523H1920v26.136s-215.341-2.406-398.038,11.066-368.227-15.763-589.2,28.768-331.03,25.569-536.367,38S0,699,0,699Z" transform="translate(1920 680) rotate(180)" fill="#bce8fa"/>
<path id="Trazado_2008" data-name="Trazado 2008" d="M0,537H1920v12.136s-215.341-2.406-398.038,11.066-368.227-15.763-589.2,28.768-331.03,25.569-536.367,38S0,699,0,699Z" transform="translate(1920 698) rotate(180)" fill="#8dbee2"/>

</svg>
</div>
<div className="pub-thi-back">
<div className="container">
<div className="pub-third-head">
<h2>Online Shopping And Affiliate Revenue Are <b>Growing</b></h2>
</div>
<div className="box">

<div className="bright col-2">
<ul>
    <li>
        <div>
            <b>GLOBAL</b>
            <p className="o">$4.29T</p>
            <span>eCommerce</span>
            <hr></hr>
            <p className="t">$4.29T</p>
            <span>Affiliate</span>
        </div>
    </li>
    <li>
    <div>
            <b>GLOBAL</b>
            <p>$4.29T</p>
            <span>eCommerce</span>
            <hr></hr>
            <p>$4.29T</p>
            <span>Affiliate</span>
        </div>
    </li>
</ul>
</div>
<div className="bleft col-10">
<h6>Affiliate Spending In Billion (US)<span>Spending in billion U.S. dollars by year.</span></h6>

<div><img src={adspendinggraphic} alt="imagfe" width="100%" /></div>


</div>

</div>
</div>
</div>
</section>

<FourthSection />
</>
  );
}

export default SecoundSection;