import React, { useState } from "react";
import "./index.css";

const Form = () => {
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [monthlyUniqueVisitors, setMonthlyUniqueVisitors] = useState("");
  const [interested, setInterested] = useState("");
  const [interestedIn, setInterestedIn] = useState([
    { id: 1, value: "marketplace", isChecked: false },
    { id: 2, value: "content", isChecked: false },
    { id: 3, value: "widget", isChecked: false },
    { id: 4, value: "cash", isChecked: false },
    { id: 5, value: "All solutions", isChecked: false }
  ]);

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [aboutIntentx, setAboutIntentx] = useState("");
  const [tellUs, setTellUs] = useState("");

  const handleCheckboxChecked = (event) => {
    let interesteds = interestedIn;
    // eslint-disable-next-line no-unused-expressions
    interesteds.forEach((lang) => {
      if (lang.value === event.target.value)
        lang.isChecked = event.target.checked;
    })
    setInterested(interesteds);
    setInterestedIn(interesteds);
  };
  const handleContactForm = async (event) => {
    event.preventDefault();
    const obj = {
      name,
      phoneNumber,
      email,
      aboutIntentx,
      tellUs,
      interested,
      monthlyUniqueVisitors,
      website,
      companyName,
    }
    const sendFormData = {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: {
        'Content-Type': 'application/json',
      }
    };
    try {
      const fetchResponse = await fetch(`https://api.intentx.com/contact-us/default`, sendFormData);
      // fetchPost(`http://api.intentx.com/contact-us/default`, {
      //   obj
      // }).then((res) => {
      //   console.log(res);
      //   window.location.href = "https://beta-cdn.intentx.com/thankyou";
      // });
      // const data = await fetchResponse.json();
      if (fetchResponse.status > 200 && fetchResponse.status < 300) {
        window.location.href = "https://beta-cdn.intentx.com/thankyou";
      }

      return fetchResponse;
    } catch (e) {
      return e;
    }
  }

  return (
    <form onSubmit={handleContactForm}>
      <div className="input">
        <label>Company Name*</label>
        <input
          value={companyName}
          onChange={e => setCompanyName(e.target.value)}
          placeholder="Company Name"
          type="text"
          name="companyName"
          required
        />
      </div>
      <div className="input">
        <label>Website*</label>
        <input
          value={website}
          onChange={e => setWebsite(e.target.value)}
          placeholder="Website"
          type="text"
          name="website"
          required
        />
      </div>

      <div className="input">
        <label>Monthly Unique Visitors</label>
        <input
          value={monthlyUniqueVisitors}
          onChange={e => setMonthlyUniqueVisitors(e.target.value)}
          placeholder="Aprox Number"
          type="text"
          name="monthlyUniqueVisitors"
          required
        />
      </div>

      <div className="input">
        <label>Interested In</label>
        <div className="intersted">
          {
            interestedIn.map((interested) => {
              return (
                <li type="none" key={interested.id}>
                  <input type="checkbox" name={interested.value} value={interested.value}
                    onChange={(handleCheckboxChecked)}
                  // onChange={e => setInterestedIn(e.target.value)}
                  />
                  <span htmlFor={interested.value} className="ml-2">{interested.value}<sup>X</sup></span>
                </li>
              );
            })
          }
        </div>
      </div>
      <div className="input">
        <label>Name*</label>
        <input
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder="John Wick"
          type="text"
          name="name"
          required
        />
      </div>

      <div className="input">
        <label>Email*</label>
        <input
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="Jonh@Mycompany.Com"
          type="email"
          name="email"
          required
        />
      </div>

      <div className="input">
        <label>Phone Number*</label>
        <input
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
          placeholder="(555) 555-555"
          type="phone"
          name="phoneNumber"
          required
        />
      </div>

      <div className="input">
        <label>How Did You Hear About intent<sup>X</sup>?</label>
        <input
          value={aboutIntentx}
          onChange={e => setAboutIntentx(e.target.value)}
          placeholder="How Did You Hear About us..."
          type="text"
          name="AboutIntentX"
        />
      </div>

      <div className="input">
        <label>Tell Us More</label>
        <textarea
          value={tellUs}
          onChange={e => setTellUs(e.target.value)}
          placeholder="Hey I Really Like To Work With You…"
          type="text"
          name="tellUs"
        />
      </div>
      <div className="input">
        <label></label>

        <div className="cbtn">
          <button className="form-button" type="submit">Send</button>
        </div>
      </div>
    </form>
  );
}

export default Form;
