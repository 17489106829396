import React from 'react';
import Button from '../../common/Button';
import "./index.css"
import iphone_accuweather from '../../../../src/staticassets/homepage/second/images/iphone_accuweather@2x.png';
import check from '../../../../src/staticassets/homepage/second/assets/checkmark/check.png';
import wayfairlogo from '../../../../src/staticassets/homepage/second/assets/brands/wayfairlogo.png';
import walmartlogo from '../../../../src/staticassets/homepage/second/assets/brands/walmartlogo.png';
import targetlogo from '../../../../src/staticassets/homepage/second/assets/brands/targetlogo.png';
const Home = () => {
    return (
        <>
            <section className="row secound">

                <div className="sleft">
                    <img src={iphone_accuweather} alt="intentx" />

                </div>
                <div className="sright">
                    <h2><span>For</span><b>Advertisers</b></h2>
                    <p>Get high-intent shoppers at scale through our network of iconic publisher partners.</p>
                    <ul>
                        <li><p><span><img src={check} alt="checkmark" width="20" /></span> <span>Editorial reviews and recommendations </span></p></li>
                        <li><p><span><img src={check} alt="checkmark" width="20" /></span> <span> No integration fee </span></p></li>
                        <li><p><span><img src={check} alt="checkmark" width="20" /></span> <span>No minimums</span></p></li>
                    </ul>
                    <Button path="advertiser-contact" btnName="START ADVERTISING" />
                    <p className="btnbtom-logo">
                        <span>
                            <img src={wayfairlogo} alt="wayfairlogo" style={{ height: 33 }} />
                        </span>
                        <span>
                            <img src={targetlogo} alt="targetlogo" style={{ height: 33 }} />

                        </span>
                        <span>
                            <img src={walmartlogo} alt="walmartlogo" style={{ height: 33 }} />

                        </span>

                    </p>
                </div>
            </section>
            <Button path="advertiser-contact" btnName="START ADVERTISING" />
            <p className="btnbtom-logo mobile">
                <span>
                    <img src={wayfairlogo} alt="wayfairlogo" style={{ height: 25 }} />
                </span>
                <span>
                    <img src={targetlogo} alt="targetlogo" style={{ height: 25 }} />

                </span>
                <span>
                    <img src={walmartlogo} alt="walmartlogo" style={{ height: 25 }} />

                </span>

            </p>
        </>
    );
}

export default Home;