import React from 'react';
// import { ThemeContext } from '../../utils/theme';
import Footer from '../../components/common/Footer';

import Header from '../../components/common/Header';
// import waves1 from '../../staticassets/thankyou/background/backgroundwaveheader@2x.png';
import "./index.css"
import PrivacyPolicy1 from '../../components/PrivacyPolicy';
// import ThirdSection from '../../components/about/ThirdSection';

const PrivacyPolicy = () => {
  return (
    <>
<Header />
{/* <div className="topimg"><img src={waves1} alt="waves1" /></div> */}
    <div className="thankyou">
    <PrivacyPolicy1 />
     {/*<ThirdSection /> */}
    </div>
<Footer />

    </>
  );
}

export default PrivacyPolicy;