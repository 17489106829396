import React from 'react';
// import { ThemeContext } from '../../utils/theme';
import Footer from '../../components/common/Footer';

import Header from '../../components/common/Header';
// import waves1 from '../../staticassets/publisher/first/background/waves1@2x.png';
import "./index.css"
import FirstSection from '../../components/advertisers/FirstSection';
import SecoundSection from '../../components/advertisers/SecoundSection';
import ThirdSection from '../../components/advertisers/ThirdSection';

const Publishers = () => {
  return (
    <>
<Header />
{/* <div className="topimg adve"><img src={waves1} alt="waves1" /></div> */}
    <div className="advertisers">
    <FirstSection />
      <div className="container">
    <SecoundSection />

    </div>
    <ThirdSection />
    </div>
<Footer />

    </>
  );
}

export default Publishers;