import React from 'react';
import "./index.css"

const FirstSection = () => {
  return (
    <>
      <section className="topsectiona">
        <article className="bannersection ab-bck">
          <div className="container">
            <div className="row">
              <div className="col-12  one">
                <h1> Closing The Loop, From Inspiration To Transaction</h1>
              </div >
            </div>
          </div>

        </article>
        <div className="svgflip2" style={{ width: '100%' }}>

          <svg style={{ position: 'relative', top: -1 }} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1920 288">
            <path id="Trazado_2028" data-name="Trazado 2028" d="M1920,487H0V621s53.051-.674,177.238-35.939,235.08-84.9,395.465-66.323S1368.684,712.919,1707.811,708c171.033-2.3,212.189-9,212.189-9Z" transform="translate(0 -487)" fill="#dae6f4"></path>
            <path id="Trazado_2029" data-name="Trazado 2029" d="M1920,507H0V641s53.051-.674,177.238-35.939S334.912,531.285,572.7,518.738,1251.58,681.951,1707.811,678C1877.72,672.319,1920,659,1920,659Z" transform="translate(0 -508)" fill="#305ab9"></path>
          </svg>
        </div>

        <div className="container">

          <div className="abt-mission">
            <h2>Our Mission</h2>
            <p>Our mission is to infer user intent and monetize through engaging offers at scale. </p>
            <p>This mission has led us to develop solutions like native ecommerce marketplaces, cash-back platforms, content, widgets, and more products that shorten the path to purchase. Our goal is to help consumers find the best offer and save the most money.
            </p>
            <p> By partnering with us, you’ll create incremental revenue streams while influencing and inspiring readers at massive scale. Our years of expertise help close the loop with proven results.</p>
          </div>
        </div>
      </section>



    </>
  );
}

export default FirstSection;