import React from 'react';
import "./index.css"
// import backgroundwaves from '../../../../src/staticassets/solutions/second/background/backgroundwave2@2x.png';
import Form from '../../Form2';
import check from '../../../../src/staticassets/homepage/second/assets/checkmark/check.png';


const SecoundSection = () => {
  return (
      <>
<section className="contadv-secound">
<div>
  <h2>We Look Forward To Working With You.</h2>
  <div className="abt-integ">
  <p><span><img src={check} alt="checkmark" /></span><span>No integration fee</span></p>
  <p><span><img src={check} alt="checkmark" /></span> <span>No minimum fee</span></p>
  </div>
</div>
<p className="contadv-formheading">Push Your Offer To Us, Or Fill Out The Form Below:</p>
<Form />
</section>
</>
  );
}

export default SecoundSection;