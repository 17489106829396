import React from 'react';
import "./index.css"
// import adspendinggraphic from '../../../staticassets/publisher/third/graph/AffiliateSpendinginBilliongraph@2x.png';
// import adrevenues from '../../../staticassets/publisher/secound/icons/adrevenues.png';
// import backgroundwaves from '../../../../src/staticassets/publisher/sixth/background/backgroundwaves4@2x.png';
import laptopdashboard from '../../../../src/staticassets/publisher/sixth/gif-dashboard.gif';

const SecoundSection = () => {
  return (
      <>
<section className="pub-seventh">
{/* <div className="massive-ywaves1"><img src={backgroundwaves} alt="yellowwaves" style={{ width: '100%'}} /></div> */}
<div className="svgflip2" style={{ marginBottom: '-5px', width: '100%'}}>
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1920 165.978">
<path id="Trazado_2013" data-name="Trazado 2013" d="M-1.491,894.01h1920v170.02s-108.314,6.878-278.519,13.068c-45.615,1.659-102.05.721-143.021,0-112.32-1.977-125.22-2.041-262.81-13.068s-271.6-49.433-408.206-64.2S542.035,998.779,299.531,993-1.491,951.845-1.491,951.845Z" transform="translate(1918.509 1078.022) rotate(180)" fill="#f0f7fb"/>
<path id="Trazado_2012" data-name="Trazado 2012" d="M-1.491,852.01h1920V995.481s-58.6-50.769-301.144-51.026-362.2,96.132-667.545,70S621.038,962.694,396,899.926-1.491,926.945-1.491,926.945Z" transform="translate(1918.509 1018.949) rotate(180)" fill="#dfedf2"/>

</svg>
</div>
<div className="pub-seventh-bck">

<div className="container">
<div className="row">
  <div className="col-4  seventh-left">
      <h1>Reporting</h1>
        <p>Reliable data tracking and real-time performance metrics provide full visibility and helpful insights. It’s your data—use it however you like.
</p>
  </div >
  <div className="col-8 seventh-right"><div className="topimage"><img src={laptopdashboard} alt="firstsection" style={{ width: '100%'}} /></div></div>
  </div>

</div>
</div>
</section>
</>
  );
}

export default SecoundSection;