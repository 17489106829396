import React from 'react';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import FirstSection from '../../components/Home/FirstSection';
import SecoundSection from '../../components/Home/SecoundSection';
import ThirdSection from '../../components/Home/ThirdSection';
import FifthSection from '../../components/Home/FifthSection';
import SixthSection from '../../components/Home/SixthSection';
import "./index.css"
// import waves1 from '../../staticassets/homepage/first/background/waves1@2x.png';
const Home = () => {
  return (
      <>
<Header />

    <div className="Home">
    {/* <div className="topimg"><img src={waves1} alt="waves1" /></div> */}
    <FirstSection />
<div className="container">
    <div className="home-secound">
        <SecoundSection />
    </div>
    </div>

<div className="home-third">
<ThirdSection />
</div>
<div className="home-fith">
<FifthSection />
</div>
<div className="home-sixth">
<SixthSection />
</div>
    </div>
<Footer />
</>
  );
}

export default Home;