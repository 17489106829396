import React, { useState } from "react";
import "./index.css";
import awin from '../../staticassets/advertiser-contact/Logos/awin.png';
import comissionjunction from '../../staticassets/advertiser-contact/Logos/comissionjunction.png';
import impactradius from '../../staticassets/advertiser-contact/Logos/impactradius.png';
import rakutenadvertising from '../../staticassets/advertiser-contact/Logos/rakutenadvertising.png';
import shareasale from '../../staticassets/advertiser-contact/Logos/shareasale.png';


const Form = () => {
  const [companyName, setCompanyName] = useState("");
  // const [website, setWebsite] = useState("");
  // const [monthlyUniqueVisitors, setMonthlyUniqueVisitors] = useState("");
  // const [interestedIn, setInterestedIn] = useState([
  //   { id: 1, value: 'marketPlacex', isChecked: false },
  //   { id: 2, value: 'contentx', isChecked: false },
  //   { id: 3, value: 'widgetx', isChecked: false },
  //   { id: 4, value: 'cashx', isChecked: false },
  //   { id: 5, value: 'allsolutions', isChecked: false }
  // ]);

  const [affiliteNetwork, setAffiliteNetwork] = useState("");

  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  // const [aboutIntentx, setAboutIntentx] = useState("");
  const [tellUs, setTellUs] = useState("");


  const handleContactForm = async (event) => {
    event.preventDefault();
    const obj = {
      fname,
      lname,
      phoneNumber,
      email,
      tellUs,
      companyName,
      affiliteNetwork,
    }
    console.log('obj dtata', obj)
    const sendFormData = {
      method: 'POST',
      body: JSON.stringify(obj),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    };
    try {
      const fetchResponse = await fetch(`https://api.intentx.com/contact-us/advertising`, sendFormData);
      // const data = await fetchResponse.json();
      if (fetchResponse.status > 200 && fetchResponse.status < 300) {
        window.location.href = "https://beta-cdn.intentx.com/thankyou";
      }
    } catch (e) {
      return e;
    }
  }

  return (
    <form onSubmit={handleContactForm}>

      <div className="advi-contact">
        <div className="advi-contact-form">
          <div className="input">
            <input
              value={fname}
              onChange={e => setFName(e.target.value)}
              placeholder="First Name"
              type="text"
              name="fname"
              required
            />
          </div>

          <div className="input">
            <input
              value={lname}
              onChange={e => setLName(e.target.value)}
              placeholder="Last Name"
              type="text"
              name="lname"
              required
            />
          </div>

          <div className="input">
            <input
              value={companyName}
              onChange={e => setCompanyName(e.target.value)}
              placeholder="Company Name"
              type="text"
              name="companyName"
              required
            />
          </div>

          <div className="input">
            <input
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Email address"
              type="email"
              name="email"
              required
            />
          </div>

          <div className="input">
            <input
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
              placeholder="Phone Number"
              type="phone"
              name="phoneNumber"
              required
            />
          </div>
          <div className="input">
            <input
              value={affiliteNetwork}
              onChange={e => setAffiliteNetwork(e.target.value)}
              placeholder="Which affiliate network your company is using?"
              type="text"
              name="affiliteNetwork"
              required
            />
            {/* <select value={affiliteNetwork} onChange={e => setAffiliteNetwork(e.target.value)}>
        <option value="">which Affiliate network your company is using?</option>
        <option value="amazon">amazon</option>
        <option value="wallmart">wallmart</option>
      </select> */}
          </div>
          <div className="input">
            <textarea
              value={tellUs}
              onChange={e => setTellUs(e.target.value)}
              placeholder="Tell Us More"
              type="text"
              name="tellUs"
              required
            />
          </div>



          <div style={{ textAlign: 'center' }}>
            <button className="form-button" type="submit">Send</button>
          </div>
        </div>
        <div className="advisor-clents">
          <div className="clents">
            <div className="ad-img"><img src={comissionjunction} alt="txt" /></div>
            <div className="ad-txt"><p>Commission Junction</p><b>ID: 4500501 intent<sup>X</sup> Inc.</b></div>
          </div>
          <div className="clents">
            <div className="ad-img"><img src={rakutenadvertising} alt="txt" /></div>
            <div className="ad-txt"><p>Rakuten Advertising</p><b>Site ID: 3267884</b></div>
          </div>
          <div className="clents">
            <div className="ad-img"><img src={impactradius} alt="txt" /></div>
            <div className="ad-txt"><p>Impact Radius</p><b>intent<sup>X</sup> Inc. ID: 161933</b></div>
          </div>
          <div className="clents">
            <div className="ad-img"><img src={shareasale} alt="txt" /></div>
            <div className="ad-txt"><p>ShareASale</p><b>intent<sup>X</sup> ID: 1980012</b></div>
          </div>
          <div className="clents">
            <div className="ad-img"><img src={awin} alt="txt" /></div>
            <div className="ad-txt"><p>Awin</p><b>intent<sup>X</sup> Inc: ID: 759085</b></div>
          </div>
        </div>
      </div>
    </form>
  );
}
export default Form;
