import React from 'react';
import "./index.css"
// import backgroundwaves from '../../../../src/staticassets/about/third/background/backgroundwaves2@2x.png';
// import hilary from '../../../../src/staticassets/about/third/teamphotos/hilary.png';
import molly from '../../../../src/staticassets/about/third/teamphotos/molly_francis.jpg';
import mom from '../../../../src/staticassets/about/third/teamphotos/mom_chan.jpg';
import ruben from '../../../../src/staticassets/about/third/teamphotos/ruben_baerga.jpg';
import samy from '../../../../src/staticassets/about/third/teamphotos/samuel_baez.jpg';
// import tommy from '../../../../src/staticassets/about/third/teamphotos/tommy.png';
import vada from '../../../../src/staticassets/about/third/teamphotos/vada_abrahamson.jpg';
import vamsi from '../../../../src/staticassets/about/third/teamphotos/vamsi_putrevu.jpg';
import sudharshan from '../../../../src/staticassets/about/third/teamphotos/Sudharshan_Srinivasan.jpg';
// import harsha from '../../../../src/staticassets/about/third/teamphotos/HarshaMR.jpg';
import bhoomi from '../../../../src/staticassets/about/third/teamphotos/bhoomi_parmar.jpg';
import deepika from '../../../../src/staticassets/about/third/teamphotos/deepika_verma.jpg';
import devayani from '../../../../src/staticassets/about/third/teamphotos/devayani_bapat.jpg';
import men from '../../../../src/staticassets/about/third/teamphotos/Men_avatar.jpg';
import women from '../../../../src/staticassets/about/third/teamphotos/Women_avatar.jpg';
import mollywolfe from '../../../../src/staticassets/about/third/teamphotos/mollywolfe.jpg'
// import marketplaceX from '../../../../src/staticassets/solutions/second/images/marketplaceX@2x.png';

const SecoundSection = () => {
  return (
    <>
      <section className="abt-secound">
        <div className="svgflip2" style={{ width: '100%' }}>
          <svg xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="0 0 1920 205" width="1920" height="205" style={{ width: '100%', height: 'auto' }}><path fill="#fff6d1" d="m1920 205h-1920v-38.5c0 0 215.3 4.4 398-20.2 182.7-24.7 368.3 38.8 589.2-42.6 221-81.5 331.1-27.8 536.4-50.5 205.3-22.7 396.4-83.2 396.4-83.2z" /><path fill="#ffe992" d="m1921 205.5h-1921v-10.5c0 0 215.5 4.4 398.2-20.2 182.8-24.7 368.5 38.8 589.6-42.6 221-81.5 331.2-26.8 536.6-49.5 205.4-22.7 396.6-81.7 396.6-81.7z" /></svg>

        </div>
        {/* <div className="massive-ywaves1"><img src={backgroundwaves} alt="yellowwaves"style={{ width: '100%'}} /></div> */}
        <div className="abt-secound-bck">

          <div className="container">
            <div className="abt-third-head">
              <h2>The Team</h2>
            </div>
            <div className="teamphots">
              <ul>
                {/*<li>
                  <div className="outer"><img src={vamsi} alt="vamsi" style={{ width: '100%' }} />

                  </div>
                  <div className="inner"><p><b>Vamsi Putrevu</b> <span>Co-Founder</span></p></div>
                </li>*/}
                <li>
                  <div className="outer"><img src={mom} alt="mom" style={{ width: '100%' }} />

                  </div>
                  <div className="inner"><p><b>Mom Chan</b> <span>Founder</span></p></div>
                </li>
                <li>
                  <div className="outer"><img src={molly} alt="molly" style={{ width: '100%' }} />

                  </div>
                  <div className="inner"><p><b>Molly Francis</b> <span>Data Engineering &amp; QA</span></p></div>
                </li>
                <li>
                  <div className="outer"><img src={samy} alt="samy" style={{ width: '100%' }} />

                  </div>
                  <div className="inner"><p><b>Samuel Baez</b> <span>Designer</span></p></div>
                </li>

                <li>
                  <div className="outer"><img src={ruben} alt="ruben" style={{ width: '100%' }} />

                  </div>
                  <div className="inner"><p><b>Ruben Baerga</b> <span>Engineer</span></p></div>
                </li>

                <li>
                  <div className="outer"><img src={deepika} alt="harsha" style={{ width: '100%' }} />
                  </div>
                  <div className="inner"><p><b>Deepika Verma</b> <span>Engineer</span></p></div>
                </li>

                <li>
                  <div className="outer"><img src={mollywolfe} alt="harsha" style={{ width: '100%' }} />
                  </div>
                  <div className="inner"><p><b>Molly Wolfe</b> <span>Content Editor</span></p></div>
                </li>

                {/* <li>
        <div className="outer"><img src={tommy} alt="tommy"style={{ width: '100%'}} />

        </div>
        <div className="inner"><p><b>Tommy So</b> <span>Engineer</span></p></div>
        </li> */}
                {/* <li>
        <div className="outer"><img src={hilary} alt="hilary"style={{ width: '100%'}} />

        </div>
        <div className="inner"><p><b>Hilary Braaksma</b> <span>Head of Content</span></p></div>
        </li> */}
                <li>
                  <div className="outer"><img src={vada} alt="vada" style={{ width: '100%' }} />

                  </div>
                  <div className="inner"><p><b>Vada Abrahamson</b> <span>Content Editor</span></p></div>
                </li>
                <li>
                  <div className="outer"><img src={devayani} alt="harsha" style={{ width: '100%' }} />
                  </div>
                  <div className="inner"><p><b>Devayani Bapat</b> <span>Content Editor</span></p></div>
                </li>

                <li>
                  <div className="outer"><img src={men} alt="harsha" style={{ width: '100%' }} />
                  </div>
                  <div className="inner"><p><b>Harsha Mr</b> <span>UI Engineer</span></p></div>
                </li>

                <li>
                  <div className="outer"><img src={sudharshan} alt="ruben" style={{ width: '100%' }} />

                  </div>
                  <div className="inner"><p><b>Sudharshan Srinivasan</b> <span>Engineer</span></p></div>
                </li>
                <li>
                  <div className="outer"><img src={men} alt="harsha" style={{ width: '100%' }} />
                  </div>
                  <div className="inner"><p><b>Dhiren Upadhyay</b> <span>Engineer</span></p></div>
                </li>

                <li>
                  <div className="outer"><img src={women} alt="harsha" style={{ width: '100%' }} />
                  </div>
                  <div className="inner"><p><b>Gowthami</b> <span>Engineer</span></p></div>
                </li>
                <li>
                  <div className="outer"><img src={men} alt="harsha" style={{ width: '100%' }} />
                  </div>
                  <div className="inner"><p><b>Soumeet Acharya</b> <span>Engineer</span></p></div>
                </li>
                <li>
                  <div className="outer"><img src={bhoomi} alt="harsha" style={{ width: '100%' }} />
                  </div>
                  <div className="inner"><p><b>Bhoomi Parmar</b> <span>Engineer</span></p></div>
                </li>



                <li>
                  <div className="outer"><img src={men} alt="harsha" style={{ width: '100%' }} />
                  </div>
                  <div className="inner"><p><b>Nisarg Pandya</b> <span>Engineer</span></p></div>
                </li>

              </ul>
            </div>

          </div>
        </div>
      </section>
    </>
  );
}

export default SecoundSection;