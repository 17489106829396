import React from 'react';
import "./index.css"

const FirstSection = () => {
  return (
    <>
      <section className="topsection">
        <div className="container">

          <article className="bannersection">
            <div className="row">
              <div className="col-12  one">
                <h1> Contact Us</h1>
              </div >
            </div>
          </article>
        </div>
      </section>
      <div className="svgflip2" style={{ width: '100%', overflow: 'hidden' }}>
        <svg style={{ position: 'relative', top: -1 }} version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 105" width="1920" height="105">
          <path fill="#dae6f4" id="Trazado_2028" class="s0" d="m0 0h1920v105c0 0-53.1-0.7-177.2-35.9-124.2-35.3-168.5 22.1-432.2 18.9-263.6-3.2-420.3-39.5-622.4-31.9-178.3 6.7-317 48.2-476 45.9-171-2.3-212.2-9-212.2-9z"></path>
          <path fill="#305ab9" id="Trazado_2029" class="s1" d="m-3 0h1927v65c0 0-157.9 18.9-324-9.2-166.2-28-374-25.2-744.3 20.7-370.4 46-858.7-33.5-858.7-33.5z"></path>
        </svg>
        {/* <svg style={{ position: 'relative', top: -1 }} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1920 105"><path id="Trazado_2028" data-name="Trazado 2028" d="M0,516H1920V621s-53.051-.674-177.238-35.939S1574.284,607.242,1310.639,604s-420.3-39.526-622.457-31.907C509.89,578.812,371.24,620.307,212.189,618,41.156,615.7,0,609,0,609Z" transform="translate(0 -516)" fill="#dae6f4"></path><path id="Trazado_2029" data-name="Trazado 2029" d="M0,536H1920v65s-157.287,18.865-322.834-9.162-372.588-25.271-741.615,20.689S0,579,0,579Z" transform="translate(0 -537)" fill="#305ab9"></path></svg> */}
      </div>
    </>
  );
}

export default FirstSection;