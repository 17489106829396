import React from 'react';
// import { ThemeContext } from '../../utils/theme';
import Footer from '../../components/common/Footer';

import Header from '../../components/common/Header';
// import waves1 from '../../staticassets/thankyou/background/backgroundwaveheader@2x.png';
import "./index.css"
import FirstSection from '../../components/thankyou/FirstSection';
import SecoundSection from '../../components/thankyou/SecoundSection';
// import ThirdSection from '../../components/about/ThirdSection';

const Thankyou = () => {
  return (
    <>
<Header />
{/* <div className="topimg"><img src={waves1} alt="waves1" /></div> */}
    <div className="thankyou">
    <FirstSection />
    <SecoundSection />
     {/*<ThirdSection /> */}
    </div>
<Footer />

    </>
  );
}

export default Thankyou;