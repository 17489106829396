import React from 'react';
// import Button from '../../common/Button';
import "./index.css"
// import FourthSection from "../FourthSection"
// import backgroundwave from '../../../staticassets/advertisers/third/backgroundwave2.png';
// import backgroundwave from '../../../staticassets/advertisers/third/backgroundwave2@2x.png';
import widgetXlaptop from '../../../staticassets/advertisers/second/image/widgetXlaptop.png';


const ThirdSection = () => {
  return (
      <>
<div className="massivetop-ywaves adv-mass">
<div className="svgflip2" style={{ marginBottom: '-37px', width: '100%'}}>
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1920 288">
<path id="Trazado_2031" data-name="Trazado 2031" d="M0,757H1920V924.714s-215.341-3.968-398.038,18.251-368.227-44.041-589.2,29.407S601.733,978.46,396.4,998.954,0,1045.6,0,1045.6Z" transform="translate(1920 1045.596) rotate(180)" fill="#f0f5fa"/>

</svg>
</div>
<div className="adv-mass-bck">

{/* <div className="massivetop-ywaves1"><img src={backgroundwave} alt="backgroundwave" style={{width: '100%'}} /></div> */}
<div className="row advrow1">
<img src={widgetXlaptop} alt="firstsection" style={{width: '100%'}} />

  </div>
<div className="container thirdrow">
<section className="massivetop-scale">
<h3>Massive Scale and Massive Audience</h3>
<div className="row">
<div className="first"><p>250M+</p><span>visits/Month</span></div>
<div className="secound"><p>1500+</p><span>Retailers</span></div>
<div className="third"><p>250M+</p><span>Products updated daily</span></div>

</div>
</section>
</div>
{/* <FourthSection /> */}
</div>
</div>
</>
  );
}

export default ThirdSection;