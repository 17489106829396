import React from 'react';
import { NavLink } from 'react-router-dom';

import './index.css';
// import backgroundwave from '../../../staticassets/Bottombar/background/backgroundwave2x.png';
// import facebook from '../../../staticassets/Bottombar/socialmedia/facebook.png';
// import instagram from '../../../staticassets/Bottombar/socialmedia/instagram.png';
// import twitter from '../../../staticassets/Bottombar/socialmedia/twitter.png';
const Footer = ({ btnName }) => {

  return (

    <div className="footer clearfix" >
      <div className="footerLinks">

    <ul>
        <li><NavLink
                exact
                to="/publishers"
                activeClassName="active"
                className="nav-links"
                // onClick={click ? handleClick : null}
              >
 Publishers
              </NavLink>
              </li>
        <li><NavLink
                exact
                to="/advertisers"
                activeClassName="active"
                className="nav-links"
                // onClick={click ? handleClick : null}
              >
 Advertisers
              </NavLink>
              </li>
        <li><NavLink
                exact
                to="/solutions"
                activeClassName="active"
                className="nav-links"
                // onClick={click ? handleClick : null}
              >
 Solutions
              </NavLink>
              </li>
        <li><NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                // onClick={click ? handleClick : null}
              >
 About Us
              </NavLink>
              </li>
        <li><NavLink
                exact
                to="/terms-of-service"
                activeClassName="active"
                className="nav-links"
                // onClick={click ? handleClick : null}
              >
Terms of service
              </NavLink>
              </li>
        <li><NavLink
                exact
                to="/privacy-policy"
                activeClassName="active"
                className="nav-links"
                // onClick={click ? handleClick : null}
              >
 Privacy Policy
              </NavLink>
              </li>
        <li><NavLink
                exact
                to="/website-terms-of-use"
                activeClassName="active"
                className="nav-links"
                // onClick={click ? handleClick : null}
              >
Website Terms
              </NavLink>
              </li>
    </ul>
</div>

      <div className="footer-bckimg">
        {/* <svg xmlns="http://www.w3.org/2000/svg" width="1920" height="155.012" viewBox="0 0 1920 155.012">
  <path id="Trazado_2021" data-name="Trazado 2021" d="M0,24s84.952-6.978,218.274,1.777S811.592,150.87,1030.661,129.387s537.776-76.824,706.595-83.079S1920,38,1920,38V197H0Z" transform="translate(0 -21.365)" fill="#d9ebf6"/>
  <path id="Trazado_2020" data-name="Trazado 2020" d="M0,0S101.411-1.375,347.8,25.155,726.811,76.594,992.1,76.594s368.48-31.787,625.261-45.892S1920,15.262,1920,15.262V155H0Z" transform="translate(0 0.012)" fill="#305ab9"/>

</svg> */}
        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 156" width="1920" height="156">
          <path fill="#d9ebf6" id="Layer" class="s0" d="m0 2.6c0 0 85-7 218.3 1.8 133.2 8.8 593.3 125.1 812.4 103.6 219-21.5 537.7-76.8 706.6-83.1 168.8-6.2 182.7-8.3 182.7-8.3v159h-1920z" />
          <path fill="#305ab9" id="Layer" class="s1" d="m-4 14c0 0 101.8-1.4 349.2 25.2 247.5 26.5 380.6 51.4 647 51.4 266.4 0 370-31.8 627.9-45.9 257.8-14.1 303.9-15.4 303.9-15.4v139.7h-1928z" />
        </svg>
      </div>
      <div className="copyandsocial">
        <div className="copyright">copyright 2024 intent<sup>X</sup>. All rights reserved</div>
      </div>

    </div>
  );
}

export default Footer;