import React from 'react';
// import { ThemeContext } from '../../utils/theme';
import Footer from '../../components/common/Footer';

import Header from '../../components/common/Header';
// import waves1 from '../../staticassets/publisher/first/background/waves1@2x.png';
import "./index.css"
import FirstSection from '../../components/publisher/FirstSection';
import SecoundSection from '../../components/publisher/SecoundSection';
import ThirdSection from '../../components/publisher/ThirdSection';
// import SixthSection from '../../components/publisher/SixthSection';
import SixthSection from '../../components/Home/FourthSection';
import SeventhSection from '../../components/publisher/SeventhSection';
import FifthSection from '../../components/publisher/FifthSection';

const Publishers = () => {
  return (
    <>
<Header />
{/* <div className="topimg pub"><img src={waves1} alt="waves1" /></div> */}
    <div className="publisher clearfix">
    <FirstSection />
      <div className="container">
    <SecoundSection />

    </div>
    <ThirdSection />
    <FifthSection />
    <div className="pub-tren">
    <SixthSection />
    </div>
    <SeventhSection />

    </div>
<Footer />

    </>
  );
}

export default Publishers;