import React from 'react';
import "./index.css"
import adspendinggraphic from '../../../staticassets/publisher/secound/graphic/ad-spending-graphic@2x.png';
import adrevenues from '../../../staticassets/publisher/secound/icons/adrevenues.png';
import cookies from '../../../staticassets/publisher/secound/icons/cookies.png';
import coupons from '../../../staticassets/publisher/secound/icons/coupons.png';
import advertisers from '../../../staticassets/publisher/secound/icons/advertisers.png';

const SecoundSection = () => {
  return (
      <>
<section className="pub-secound">
<div className="pub-secound-head">
<h2>Crisis To Diversify</h2>
<p>Publishers can no longer rely on standard ad revenue.</p>
</div>
<div className="box">
<div className="bleft col-7">
<h6>US Digital Ad Spending Growth on Select Channels, 2018-2023.</h6>
<div className="magz"><span className="mag"></span>Magazines</div>

<div><img src={adspendinggraphic} alt="imagfe" width="100%" /></div>


</div>
<div className="bright col-5">
<ul>
    <li>
        <div><img src={adrevenues} alt="imagfe" width='73' height='73' /></div>
        <div>
            <b>Ad revenues</b>
            <p>Ad revenues are plummeting, requiring
publishers to diversify their offerings.
</p>
        </div>
    </li>
    <li>
        <div><img src={cookies} alt="imagfe" width='73' height='73' /></div>
        <div>
            <b>Third Party cookies </b>
            <p>Third Party cookies are going away, removing key customer data points for personalized advertising.</p>
        </div>
    </li>
    <li>
        <div><img src={coupons} alt="imagfe" width='73' height='73' /></div>
        <div>
            <b>Coupons</b>
            <p>88% of online shoppers (~200M users) abandoned their carts to search for a coupon. Publishers are losing affiliate referrals to just-in-time coupon extensions.
</p>
        </div>
    </li>
    <li>
        <div><img src={advertisers} alt="imagfe" width='73' height='73' /></div>
        <div>
            <b>Advertisers</b>
            <p>Opportunities exist for advertisers to better leverage consumers’ rich ecommerce data (e.g. stores visited, interested categories, products viewed, purchase history, etc.)
</p>
        </div>
    </li>
</ul>
</div>

</div>
</section>
</>
  );
}

export default SecoundSection;