import React from 'react';
import "./index.css"
import editorchoice from '../../../staticassets/advertisers/second/image/editorchoice.png';
import sponsoredcontent from '../../../staticassets/advertisers/second/image/sponsoredcontent.png';
// import widgetXlaptop from '../../../staticassets/advertisers/second/image/widgetXlaptop.png';

const SecoundSection = () => {
  return (
      <>
<section className="adv-secound">
<div className="adv-head">
<h2>More Than Just Product Discovery</h2>
<p>Get immediate exposure and scale with high-intent shoppers, high conversion rates, and high outreach volume. There are no integration costs and no minimum fees.</p>
</div>
<div className="adv-im1">
<div className="row advrow">

  <div className="col-5 advtwo"><div className="topimage"><img src={editorchoice} alt="firstsection" style={{width: '100%'}} /></div></div>
  <div className="col-5  advone">
        <h3>Editor’s Choice</h3>
      <p>Featured products are hand-selected by editors and highlighted in the publisher shop. Get brand and product exposure to high-intent shoppers quickly.</p>
  </div >
  </div>
<div className="row advrow">

  <div className="col-5  advone">
        <h3>Sponsored Content</h3>
      <p>Editorial content written in-house and featured on top publisher shop websites, featuring products hand-selected by advertisers.</p>
  </div >
  <div className="col-5 advtwo"><div className="topimage"><img src={sponsoredcontent} alt="firstsection" style={{width: '100%'}} /></div></div>

  </div>
  </div>
{/* <div className="row advrow1">
<img src={widgetXlaptop} alt="firstsection" style={{width: '100%'}} />

  </div> */}
</section>
</>
  );
}

export default SecoundSection;