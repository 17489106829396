import React from 'react';
import "./index.css"
import firstsectionimg from '../../../../src/staticassets/solutions/first/image/OneStopShop_ipad@2x.png';

const Home = () => {
  return (
    <>
      <section className="topsection1">
        <article className="bannersection">
          <div className="row">
            <div className="col-12  one">
              <div className="container">

                <h1>Leading Affiliate Commerce Platform <b> For Publishers.</b></h1>
                <p>One Stop Shop For Publishers</p>
              </div >

            </div>
            <div className="svgflip2" style={{ marginTop: '-5px', width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
              <svg style={{ position: 'relative', top: -1 }} xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="0 0 1920 186" width="1920" height="186">
                <path fill="#dae6f4" id="Trazado_2022" d="m1920 215.8c0 0-84.8 7-218.2-1.7-133.3-8.8-593.3-125.1-812.3-103.6-219.1 21.4-537.8 76.8-706.6 83-168.9 6.3-182.9 8.3-182.9 8.3v-201.8h1920z" />
                <path fill="#305ab9" id="Trazado_2023" d="m1923 195.8c0 0-101.6 1.4-348.6-25.1-247-26.5-380-51.4-646-51.4-266 0-369.4 31.7-626.9 45.8-257.4 14.1-303.5 15.5-303.5 15.5v-182.6h1925z" />
              </svg>
            </div>
            <div className="soltwo">
              <div className="container">
                <div className="topimage"><img src={firstsectionimg} alt="firstsection" /></div>
                <p className="toptxt">Immediate scale and seamless integration. intent<sup>X</sup> does most of the work.</p>
              </div>
            </div>
          </div>
        </article>
      </section>
    </>
  );
}

export default Home;