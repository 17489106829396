import React from 'react';
import Button from '../../common/Button';
import "./index.css"
import firstsectionimg from '../../../../src/staticassets/publisher/first/images/firstsection@2x.png';

const Home = () => {
  return (
    <>
      <section className="topsection">
        <div className="container">

          <article className="bannersection">
            <div className="row">
              <div className="col-5  one">
                <h1>Leading Commerce Platform For <b>Publishers.</b></h1>
                <p>A turn-key affiliate commerce platform that generates incremental revenue with fast and seamless integration.
                </p>
                <div className="btn">
                  <Button path="contact" btnName="START MONETIZING" />

                </div>
              </div >
              <div className="col-7 two"><div className="topimage"><img src={firstsectionimg} alt="firstsection" /></div></div>
            </div>
          </article>
        </div>
      </section>
      <div className="svgflip2" style={{ marginBottom: '-5px', width: '100%', maxWidth: '100%', overflow: 'hidden', position: 'relative', top: '-2px' }}>

        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 232" width="1920" height="232">
          <path fill="#d9ebf6" id="Trazado_2085" d="m0 0h1920v218c0 0-108.3 6.9-278.5 13.1-45.6 1.7-102.1 0.7-143 0-112.4-2-125.3-2-262.8-13.1-137.6-11-271.6-59.4-408.3-74.2-136.6-14.7-283.9-21-526.4-26.8-242.5-5.8-301-11.2-301-11.2z" />
          <path fill="#305ab9" id="Trazado_2086" d="m-2-6h1922v196.2c0 0-58.7-0.8-301.5-1-242.8-0.3-362.5 26.7-668.2 0-305.7-26.8-330-66.4-554.4-107.1-224.4-40.8-397.9-23.6-397.9-23.6z" />
        </svg>

        {/* <svg style={{ position: 'relative', top: -1 }} xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1920 232"><path id="Trazado_2085" data-name="Trazado 2085" d="M-1.489,846h1920v218.029s-108.314,6.878-278.519,13.068c-45.615,1.659-102.05.721-143.021,0-112.32-1.977-125.22-2.041-262.81-13.068s-271.6-59.433-408.206-74.2S542.035,968.779,299.531,963-1.491,951.845-1.491,951.845Z" transform="translate(1.491 -846)" fill="#d9ebf6"></path><path id="Trazado_2086" data-name="Trazado 2086" d="M-1.491,804h1920V995.481s-58.6-.769-301.144-1.026-362.2,26.132-667.545,0S620.146,929.688,396,889.926-1.491,866.945-1.491,866.945Z" transform="translate(1.491 -805)" fill="#305ab9"></path></svg> */}
      </div>
    </>
  );
}

export default Home;