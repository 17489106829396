import React from 'react';
// import Button from '../../common/Button';
import "./index.css"
import FourthSection from "../FourthSection"
// import yellowwaves from '../../../../src/staticassets/homepage/third/background/yellowwaves2@2x.png';

const ThirdSection = () => {
  return (
      <>
<div className=" svgflip1">

<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1920 230">
<path id="Trazado_2009" data-name="Trazado 2009" d="M0,497H1920v80.136s-215.341-2.406-398.038,11.066-368.227-15.763-589.2,28.768-331.03,25.569-536.367,38S0,699,0,699Z" transform="translate(1920 699) rotate(180)" fill="#fff5d0"/>

<path id="Trazado_2007" data-name="Trazado 2007" d="M0,483H1920v66.136s-215.341-2.406-398.038,11.066-368.227-15.763-589.2,28.768-331.03,25.569-536.367,38S0,699,0,699Z" transform="translate(1920 699) rotate(180)" fill="#ffeea8"/>
<path id="Trazado_2008" data-name="Trazado 2008" d="M0,469H1920v52.136s-215.341-2.406-398.038,11.066-368.227-15.763-589.2,28.768-331.03,25.569-536.367,38S0,699,0,699Z" transform="translate(1920 699) rotate(180)" fill="#ffe782"/>
{/* <path id="Trazado_2009" data-name="Trazado 2009" d="M0,469H1920v80.136s-215.341-2.406-398.038,11.066-368.227-15.763-589.2,28.768-331.03,25.569-536.367,38S0,699,0,699Z" transform="translate(1920 699) rotate(180)" fill="#fff5d0"/>

<path id="Trazado_2007" data-name="Trazado 2007" d="M0,483H1920v66.136s-215.341-2.406-398.038,11.066-368.227-15.763-589.2,28.768-331.03,25.569-536.367,38S0,699,0,699Z" transform="translate(1920 699) rotate(180)" fill="#ffeea8"/>
<path id="Trazado_2008" data-name="Trazado 2008" d="M0,497H1920v52.136s-215.341-2.406-398.038,11.066-368.227-15.763-589.2,28.768-331.03,25.569-536.367,38S0,699,0,699Z" transform="translate(1920 699) rotate(180)" fill="#ffe782"/> */}
</svg>


</div>
<div className="massive-ywaves ">
{/* <div className="massive-ywaves1"> */}
{/* <div className=" svgflip">
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="230" viewBox="0 0 1920 230">
  <path id="Trazado_2009" data-name="Trazado 2009" d="M0,469H1920v80.136s-215.341-2.406-398.038,11.066-368.227-15.763-589.2,28.768-331.03,25.569-536.367,38S0,699,0,699Z" transform="translate(1920 699) rotate(180)" fill="#fff5d0"/>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="216"  viewBox="0 0 1920 216">
  <path id="Trazado_2007" data-name="Trazado 2007" d="M0,483H1920v66.136s-215.341-2.406-398.038,11.066-368.227-15.763-589.2,28.768-331.03,25.569-536.367,38S0,699,0,699Z" transform="translate(1920 699) rotate(180)" fill="#ffeea8"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="202"  viewBox="0 0 1920 202">
  <path id="Trazado_2008" data-name="Trazado 2008" d="M0,497H1920v52.136s-215.341-2.406-398.038,11.066-368.227-15.763-589.2,28.768-331.03,25.569-536.367,38S0,699,0,699Z" transform="translate(1920 699) rotate(180)" fill="#ffe782"/>
</svg>
</div> */}

{/* </div> */}
{/* <div className="massive-ywaves1"><img src={yellowwaves} alt="yellowwaves" /></div> */}
<div className="massivebck ">
<div className="container thirdrow">
<section className="massive-scale">
<h3>Massive Scale and Massive Audience</h3>
<div className="row">
<div className="first"><p>250M+</p><span>visits/Month</span></div>
<div className="secound"><p>1500+</p><span>RETAILERS</span></div>
<div className="third"><p>250M+</p><span>PRODUCTS UPDATED DAILY</span></div>

</div>
</section>
</div>
</div>
<FourthSection />
</div>
</>
  );
}

export default ThirdSection;