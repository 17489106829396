import React from 'react';
// import Button from '../../common/Button';
import "./index.css"
// import yellowwaves from '../../../../src/staticassets/homepage/third/background/yellowwaves2@2x.png';
// import accuweather from 'https://duf0na3s2cmll.cloudfront.net/staticassets/homepage/fourth/brands/accuweather.png';
// import dailymail from 'https://duf0na3s2cmll.cloudfront.net/staticassets/homepage/fourth/brands/dailymail.png';
// import earth from 'https://duf0na3s2cmll.cloudfront.net/staticassets/homepage/fourth/brands/earth.png';
// import fandom from 'https://duf0na3s2cmll.cloudfront.net/staticassets/homepage/fourth/brands/fandom.png';
// import hometalk from 'https://duf0na3s2cmll.cloudfront.net/staticassets/homepage/fourth/brands/hometalk.png';
// import ibt from 'https://duf0na3s2cmll.cloudfront.net/staticassets/homepage/fourth/brands/ibt.png';
// import shefinds from 'https://duf0na3s2cmll.cloudfront.net/staticassets/homepage/fourth/brands/shefinds.png';
// import sportspyder from 'https://duf0na3s2cmll.cloudfront.net/staticassets/homepage/fourth/brands/sportspyder.png';
import Carousel from "react-multi-carousel";

const Home = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    }
  };
  const images = [
     'https://duf0na3s2cmll.cloudfront.net/staticassets/homepage/fourth/brands/accuweather.png',
 'https://duf0na3s2cmll.cloudfront.net/staticassets/homepage/fourth/brands/dailymail.png',
 'https://duf0na3s2cmll.cloudfront.net/staticassets/homepage/fourth/brands/earth.png',
 'https://duf0na3s2cmll.cloudfront.net/staticassets/homepage/fourth/brands/fandom.png',
 'https://duf0na3s2cmll.cloudfront.net/staticassets/homepage/fourth/brands/hometalk.png',
 'https://duf0na3s2cmll.cloudfront.net/staticassets/homepage/fourth/brands/ibt.png',
 'https://duf0na3s2cmll.cloudfront.net/staticassets/homepage/fourth/brands/shefinds.png',
 'https://duf0na3s2cmll.cloudfront.net/staticassets/homepage/fourth/brands/sportspyder.png',
  ];
  // const images = [
  //   accuweather, dailymail, earth, fandom, hometalk, ibt, shefinds, sportspyder
  // ];
  return (
      <>
<div className="trusted ">
  <h3><span>Trusted Partner of</span><b>Leading Publishers</b></h3>
  <Carousel
      // ssr
      // partialVisbile
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={2000}
      arrows={false}
      // deviceType={deviceType}
      itemClass="image-item"
      responsive={responsive}
    >
      {images.map(image => {
        return (
          <div className="carosoulb ox">
                  <div className="carosouluserimg">
          <img src={image} alt="img" />
                  </div>

              </div>
        );
      })}
    </Carousel>

</div>
</>
  );
}

export default Home;