import React from 'react';
import { useNavigate } from 'react-router-dom';

import './index.css';


const Button = ({ btnName, path }) => {
  let history = useNavigate();

  const redirect = () => {
    history(`/${path}`)
  }
  return (

    <div className="button" >

      <button onClick={redirect} >{btnName}</button>
    </div>
  );
}

export default Button;