import React from  'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
// import { theme, ThemeContext } from '../utils/theme';
import Home from '../pages/Home';
import Publishers from '../pages/Publishers';
import Solutions from '../pages/Solutions';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Advertisers from '../pages/Advertisers';
import Thankyou from '../pages/Thankyou';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsOfService from '../pages/TermsOfService';
import WebsiteTermsofUse from '../pages/WebsiteTermsofUse';
// import ContactAdvisor from '../pages/ContactAdvisor';


const App = () => {
  // const [themei , setTheme] = useState(theme.intentx);
  // setTheme({themei: theme.intentx});

  return (
    // <ThemeContext.Provider value={theme.intentx}>
    <Router>
      <Routes>
        <Route
          path="/"
          element={
              <Home />
          }
        />
        <Route
          path="/publishers"
          element={
            <Publishers />
        }
        />
        <Route
          path="/advertisers"
          element={
            <Advertisers />
        }
        />
        <Route
          path="/solutions"
          element={
            <Solutions />
        }
        />
        <Route
          path="/about"
          element={
            <About />
        }
        />

        <Route
          path="/contact"
          element={
            <Contact />
        }
        />
        <Route
          path="/advertiser-contact"
          element={
            <Contact />
        }
        />
        <Route
          path="/thankyou"
          element={
            <Thankyou />
        }
        />
         <Route
              path="/privacy-policy"
              element={
                <PrivacyPolicy  />
              }
            />
            <Route
              path="/website-terms-of-use"
              element={
                <WebsiteTermsofUse  />
              }
            />
            <Route
              path="/terms-of-service"
              element={
                <TermsOfService  />
              }
            />
   </Routes>
</Router>
      // </ThemeContext.Provider>
  );
}

export default App;
